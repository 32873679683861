const uploadImageModalStyle = (theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  noPadding: {
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      height: "inherit",
    },
  },

  imageUpload: {
    width: "100%",
    marginTop: 30,
  },
  button: {
    margin: "auto",
    marginBottom: 30,
    padding: "4px 10px",
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
});

export default uploadImageModalStyle;
