import testimonialBg from "assets/images/testimonial-bg.png";
import {
  blackColor,
  primaryColor,
  textColor,
} from "assets/jss/material-kit-pro-react";
import { mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.js";

const ourClientsStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  bg: {
    marginLeft: -15,
    marginRight: -15,
    padding: "0px 15px",
    marginBottom: 80,
    backgroundImage: `url(${testimonialBg})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none",
    },
    "& .slick-dots li.slick-active button:before": {
      backgroundColor: primaryColor[0],
      boxShadow:
        "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)",
      width: 12,
      height: 12,
      top: 0,
    },
    "& .slick-prev::before": {
      color: blackColor,
    },
    "& .slick-next::before": {
      color: blackColor,
    },
    [theme.breakpoints.down("sm")]: {
      "& .slick-dots": {
        bottom: -15,
      },
    },
  },
  ourClientsSection: {},
  clientCard: {
    maxWidth: "650px",
    margin: "60px auto",
  },
  ourCliensHeader: {
    textAlign: "center",
    color: textColor[0],
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
      fontSize: "1.5rem",
    },
  },
  testimonialHeader: {
    textAlign: "center",
    color: textColor[0],
    textTransform: "uppercase",
    marginTop: 80,
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
      fontSize: "1.5rem",
    },
  },
  clientLogo: {
    color: "inherit",
    maxHeight: 200,
    maxWidth: 200,
    objectFit: "contain",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "50vw",
    },
  },
  clientLogoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 500,
    maxHeight: 300,
    margin: "40px auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "50vw",
    },
  },
});

export default ourClientsStyle;
