import gear from "assets/images/gear.png";
import { primaryColor, textColor } from "assets/jss/material-kit-pro-react";
import { mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.js";

const whoWeAreStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  whoWeAreSection: {
    borderBottom: "1px solid #aaa",
    paddingBottom: 60,
    marginBottom: 60,
  },
  whoWeAreTopSection: {
    backgroundImage: `url(${gear})`,
    backgroundSize: "contain",
    backgroundPosition: "top center",
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    padding: "20px 0px",
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none",
    },
  },
  infoSection: {
    padding: "100px 40px",
    paddingTop: 150,
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  infoSingle: {
    padding: "0px 60px",
    marginBottom: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 400,
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10px",
    },
  },
  whoWeAreUnderline: {
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
      height: 1,
      marginTop: -40,
      backgroundColor: primaryColor[0],
      borderRadius: 100,
      marginBottom: 20,
    },
  },
  headerUnderline: {
    borderRadius: 100,
    backgroundColor: primaryColor[0],
    height: 5,
    width: 20,
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
      height: 0,
      marginTop: 5,
    },
  },
  infoHeader: {
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "center",
    },
  },
  infoBody: {
    display: "flex",
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  infoImage: {
    margin: "40px auto",
  },
  whoWeAreHeader: {
    textTransform: "uppercase",
    color: textColor,
    textAlign: "center",
    marginBottom: 60,
  },
  collage: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      margin: "0px -15px",
      width: "110%",
    },
  },
  collageDescriptoin: {
    textAlign: "center",
    margin: "auto",
    maxWidth: "80%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      margin: 0,
    },
  },
  person: {
    width: "90%",
    marginLeft: "auto",
    objectFit: "contain",
    maxHeight: 600,
    maxWidth: 440,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

export default whoWeAreStyle;
