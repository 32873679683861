export default {
  paginationNext: "NEXT",
  paginationPrev: "PREV",
  noAvailableData: "No data available",
  login: "Log in",
  signUp: "Sign Up",
  signIn: "Sign In",
  alreadyMember: "Already a member?",
  notMember: "Not a member?",
  shortWeekDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  usingEmail: "Using Email",
  firstTitleEmailConfirmation: "A Confirmation Email has been sent to:",
  secondTitleEmailConfirmation:
    "Please check your inbox and confirm your Email",
  resendEmail: "Did not receive email? Resend",
  retryResendEmail: "Resend mail again in",
  firstTitleForgotSucceed: "We've sent you a message at:",
  secondTitleForgotSucceed:
    "Follow the link in that message to reset your password",
  ok: "Ok",
  somethingWrong: "Something went wrong, please try again later",
  orderCreationFailed:
    "Payment processed successfuly but course registration failed. Please contact support.",
  privacyPolicy: "Privacy Policy",
  privacyPolicyParagraph: [
    `Your privacy is important to us. It is Greymatteru's policy to respect your privacy regarding any information we may collect from you across our website, https://greymatteru.com, and other sites we own and operate.\n
    We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.\n
    We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.\n
    We don’t share any personally identifying information publicly or with third-parties, except when required to by law.\n
    Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.\n
    You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.\n
    Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.`,
  ],
  howWeCollectDataAboutYou: "How we collect Data about you",
  howWeCollectDataAboutYouParagraph:
    "We collect data from you directly, like information you enter and data from and data from third-party platforms you connect with Greymatteru. Parents who believe we’ve unintentionally collected personal data about their underage child should contact us for help deleting that information.",
  termsAndConditions: "Terms & Condtions",
  termsAndConditionsContent: [
    {
      title: "Terms",
      paragraph:
        "By accessing the website at https://greymatteru.com, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.",
    },
    {
      title: "Use License",
      paragraph: `• Permission is granted to temporarily download one copy of the materials (information or software) on Greymatteru's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:\n
      \u2003\u2003•	modify or copy the materials.\n
      \u2003\u2003•	use the materials for any commercial purpose, or for any public display (commercial or non-commercial).\n
      \u2003\u2003•	attempt to decompile or reverse engineer any software contained on Greymatteru’s website.\n
      \u2003\u2003•	remove any copyright or other proprietary notations from the materials.\n
      \u2003\u2003•	transfer the materials to another person or "mirror" the materials on any other server.\n
      •	This license shall automatically terminate if you violate any of these restrictions and may be terminated by Greymatteru at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.`,
    },
    {
      title: "Payments and refunds ",
      paragraph:
        "When you make a payment, you agree to use a valid payment method. If you aren’t happy with your course, Greymatteru offers a 15-day refund or credit for recorded course purchases. Greymatteru offers a full refund for the live sessions when you attend less than 20% of the course.",
    },
    {
      title: "Limitations",
      paragraph:
        "In no event shall Greymatteru or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Greymatteru's website, even if Greymatteru or a Greymatteru authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.",
    },
    {
      title: "Accuracy of materials",
      paragraph:
        "The materials appearing on Greymatteru's website could include technical, typographical, or photographic errors. Greymatteru does not warrant that any of the materials on its website are accurate, complete or current. Greymatteru may make changes to the materials contained on its website at any time without notice. However Greymatteru does not make any commitment to update the materials.",
    },
    {
      title: "Links",
      paragraph:
        "Greymatteru has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Greymatteru of the site. Use of any such linked website is at the user's own risk.",
    },
    {
      title: "Modifications",
      paragraph:
        "Greymatteru may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.",
    },
    {
      title: "Governing Law",
      paragraph:
        "These terms and conditions are governed by and construed in accordance with the laws of United Arab of Emirates and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.",
    },
    {
      title: "Payments and refunds",
      paragraph:
        "When you make a payment, you agree to use a valid payment method. If you aren’t happy with your course, Greymatteru offers a 15-day refund or credit for recorded course purchases. Greymatteru offers a full refund for the live sessions when you attend less than 20% of the course.",
    },
    {
      title: "International use",
      paragraph:
        "Greymatteru operates the services in the United Arab Emirates. If you choose to access our website from locations outside the United Arab Emirates, you consent to the collection, transmission, use, storage and processing of content and data in the United Arab Emirates. You also agree to comply with al local laws. If you are under the age of 18, you confirm that you have received parental consent.",
    },
    {
      title: "Disclaimer",
      paragraph: `•	The materials on Greymatteru's website are provided on an 'as is' basis. greymatteru makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.\n
      •	Further, Greymatteru does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.`,
    },
  ],
};
