import certificateBg from "assets/images/certificate-diploma.png";
import { blackColor } from "assets/jss/material-kit-pro-react";
import { textColor } from "assets/jss/material-kit-pro-react";
import {
  container,
  mlAuto,
  mrAuto,
} from "assets/jss/material-kit-pro-react.js";

const coursesStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  root: {
    padding: "0px 0px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "60px 0px",
      paddingTop: 120,
    },
  },
  container: {
    ...container,
    zIndex: 1,
  },
  headerImageContainer: {
    minHeight: 185,
    [theme.breakpoints.down("sm")]: {
      minHeight: 0,
    },
  },
  coursesHeaderImage: {
    width: "100%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  shadowContainer: {
    boxShadow: "0 2.5px 25px 0 rgba(0, 0, 0, 0.5)",
    width: "90%",
    borderRadius: 32.5,
    backgroundSize: "contain",
    backgroundPosition: "top center",
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    height: "100%",
    padding: "40px 15px",
    marginTop: 60,
    marginBottom: 60,
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px",
      boxShadow: "none",
      width: "100%",
      borderRadius: 0,
      backgroundImage: "none",
    },
  },
  coursesSection: {
    backgroundSize: "70%",
    backgroundPosition: "top center",
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${certificateBg})`,
    borderBottom: "1px solid #aaa",
    marginBottom: 60,
    padding: "20px 40px",
    paddingTop: "10px",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none",
      padding: "20px 0px",
    },
  },
  coursesHeader: {
    textAlign: "center",
    color: textColor[0],
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
      fontSize: "1.5rem",
    },
  },
  loveToLearnText: {
    color: textColor,
    fontWeight: 300,
    marginTop: 60,
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
      fontSize: "1.5rem",
      textAlign: "center",
    },
  },
  subtitle: {
    color: textColor,
    fontWeight: 300,
  },
  coursesContainer: {
    "& .slick-prev::before": {
      color: blackColor,
    },
    "& .slick-next::before": {
      color: blackColor,
    },
    "& .slick-prev": {
      left: -100,
    },
    "& .slick-next": {
      right: -100,
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
});

export default coursesStyle;
