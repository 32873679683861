/*eslint-disable*/
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import coursesImage from "assets/images/courses.png";
import strings from "constants/strings";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import api from "services/api";
import { msToTime } from "utils";

import emailConfirmationStyle from "./emailConfirmationStyle.js";

const useStyles = makeStyles(emailConfirmationStyle);

let resendTimerInterval;

const timerDuration = 30;
const timerStep = 1;

export default function EmailConfirmation({ ...rest }) {
  const [values, setValues] = React.useState({
    email: "",
  });

  const lang = useSelector((state) => state.lang);

  const user = useSelector((state) => state.auth.user);

  const classes = useStyles();

  const history = useHistory();

  const [resendRemainingTime, setResendRemainingTime] = React.useState(
    timerDuration * 1000
  );

  useEffect(() => {
    if (user) {
      const updatedEmail = user.email;

      setValues({
        ...values,
        email: updatedEmail,
      });
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);

    (async () => {
      try {
        const lastEmailConfirmation = (await api.checkResendEmailConfirmation())
          .data.lastEmailConfirmation;

        const t1 = new Date(new Date().toUTCString());
        const t2 = new Date(new Date(lastEmailConfirmation).toUTCString());
        let timeRemaining = t1.getTime() - t2.getTime();

        timeRemaining =
          timeRemaining > timerDuration * 1000
            ? 0
            : timerDuration * 1000 - timeRemaining;

        setResendRemainingTime(timeRemaining);
      } catch (err) {}
    })();
  }, []);

  useEffect(() => {
    startResendTimer();

    return () => {
      if (resendTimerInterval) {
        clearInterval(resendTimerInterval);
      }
    };
  }, [resendTimerInterval]);

  const startResendTimer = () => {
    if (resendTimerInterval) {
      clearInterval(resendTimerInterval);
    }

    resendTimerInterval = setInterval(() => {
      if (resendRemainingTime <= 0) {
        clearInterval(resendTimerInterval);
      } else {
        setResendRemainingTime(resendRemainingTime - timerStep * 1000);
      }
    }, timerStep * 1000);
  };

  const resendConfirmationEmailHandler = async () => {
    setResendRemainingTime(timerDuration * 1000);
    startResendTimer();

    try {
      await api.resendEmailConfirmation();
    } catch (err) {}
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img
          className={classes.coursesHeaderImage}
          src={coursesImage}
          alt="coursesImage"
        />
      </div>
      <div className={classes.container + " " + classes.boxBackground}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <div className={classes.title}>
                  {strings.firstTitleEmailConfirmation}
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <div className={classes.emailTitle}>{values.email}</div>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <div className={classes.title}>
                  {strings.secondTitleEmailConfirmation}
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={10} sm={10} md={10}>
                {resendRemainingTime > 0 ? (
                  <div className={classes.resendEmailContainer}>
                    {`${strings.retryResendEmail} ${msToTime(
                      resendRemainingTime
                    )} seconds`}
                  </div>
                ) : (
                  <div
                    className={
                      classes.resendEmailContainer + " " + classes.pointer
                    }
                    onClick={resendConfirmationEmailHandler}
                  >
                    {strings.resendEmail}
                  </div>
                )}
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
