import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import coursesImage from "assets/images/courses.png";
import { primaryColor } from "assets/jss/material-kit-pro-react.js";
import classNames from "classnames";
import CourseCard from "components/CourseCard/CourseCard.js";
import CourseCardSkeleton from "components/CourseCard/CourseCardSkeleton.js";
import GridContainer from "components/Grid/GridContainer.js";
import OurClients from "components/OurClients/OurClients.js";
import React from "react";
import api from "services/api.js";
import AllCoursesList from "./AllCoursesList/AllCoursesList";
import style from "./coursesStyle.js";

const useStyles = makeStyles(style);

export default function Courses() {
  const [featuredCourses, setFeaturedCourses] = React.useState([]);
  const [newCourses, setNewCourses] = React.useState([]);
  const [courses, setCourses] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [, forceRerender] = React.useState(0);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.addEventListener("resize", () => forceRerender((n) => !n));
    (async () => {
      try {
        setLoading(true);
        const featuredAndNewCoursesResponse = (
          await api.getFeaturedAndNewCourses()
        ).data;
        const allCoursesResponse = (await api.getCourses()).data.data;
        setFeaturedCourses(featuredAndNewCoursesResponse.featured);
        setNewCourses(featuredAndNewCoursesResponse.newReleases);
        setCourses(allCoursesResponse);
        setLoading(false);
      } catch (err) {
        // setLoading(false);
      }
    })();
    return () => {
      window.removeEventListener("resize", () => forceRerender((n) => !n));
    };
  }, []);

  let newCoursesElements = [];
  let featuredCoursesElements = [];

  if (newCourses.length > 0) {
    newCourses.forEach((course, index) => {
      if (index < 3)
        newCoursesElements.push(<CourseCard key={index} course={course} />);
    });
  }
  if (featuredCourses.length > 0) {
    featuredCourses.forEach((course, index) => {
      if (index < 3)
        featuredCoursesElements.push(
          <CourseCard key={index} course={course} />
        );
    });
  }

  const classes = useStyles();
  return (
    <div>
      <div className={classNames(classes.root)}>
        <div className={classes.container + " " + classes.headerImageContainer}>
          <img
            className={classes.coursesHeaderImage}
            src={coursesImage}
            alt="coursesImage"
          />
        </div>
        <div className={classes.container + " " + classes.shadowContainer}>
          <div className={classes.coursesSection}>
            <h3 className={classes.coursesHeader}>All Courses</h3>
            <h2 className={classes.loveToLearnText}>
              Love to Learn{" "}
              <span style={{ color: primaryColor[1] }}>Online</span>. Enroll
              Now!
            </h2>
            {loading ? (
              <div style={{ backgroundColor: "#fffa" }}>
                <Skeleton variant="rect">
                  <h3 className={classes.subtitle}>New Releases</h3>
                </Skeleton>
                <GridContainer className={classes.coursesContainer}>
                  <CourseCardSkeleton />
                  <CourseCardSkeleton />
                  <CourseCardSkeleton />
                </GridContainer>
                <Skeleton variant="rect">
                  <h3 className={classes.subtitle}>Featured</h3>
                </Skeleton>
                <GridContainer className={classes.coursesContainer}>
                  <CourseCardSkeleton />
                  <CourseCardSkeleton />
                  <CourseCardSkeleton />
                </GridContainer>
              </div>
            ) : (
              <div>
                {newCourses.length > 0 ? (
                  <div>
                    <h3 className={classes.subtitle}>New Releases</h3>
                    <GridContainer className={classes.coursesContainer}>
                      {newCoursesElements}
                    </GridContainer>
                  </div>
                ) : null}
                {featuredCourses.length > 0 ? (
                  <div>
                    <h3 className={classes.subtitle}>Featured</h3>
                    <GridContainer className={classes.coursesContainer}>
                      {featuredCoursesElements}
                    </GridContainer>
                  </div>
                ) : null}
                {courses.length > 0 ? (
                  <div>
                    <h3 className={classes.subtitle}>All Courses</h3>
                    <AllCoursesList courses={courses} />
                  </div>
                ) : null}
              </div>
            )}
          </div>
          <OurClients />
        </div>
      </div>
    </div>
  );
}
