import { makeStyles } from "@material-ui/core/styles";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { Rating } from "@material-ui/lab";
import courseImage1 from "assets/images/kais_small.png";
import timer from "assets/images/timer.png";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import React from "react";
import style from "./courseCardStyle.js";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(style);

export default function CourseCardSkeleton(props) {
  const classes = useStyles();
  const { long, mAuto } = props;
  const rootClasses = classNames({
    [classes.mAuto]: mAuto,
    [classes.root]: true,
  });
  const imageContainerClasses = classNames({
    [classes.longCard]: long,
    [classes.imageContainer]: true,
  });
  return (
    <div className={rootClasses}>
      <div className={classes.container}>
        <Skeleton style={{ margin: "auto" }} variant="rect">
          <div className={imageContainerClasses}>
            <img className={classes.image} src={courseImage1} alt="img1" />
          </div>
        </Skeleton>
        <div className={classes.infoContainer}>
          <Skeleton style={{ marginBottom: 10 }} variant="rect" width="100%" />
          <Skeleton style={{ marginBottom: 10 }} variant="rect" width="80%" />
          <Skeleton style={{ marginBottom: 10 }} variant="rect" width="50%" />
        </div>
      </div>
    </div>
  );
}
