import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

export default function ClientLogoSkeleton(props) {
  return (
    <Skeleton
      style={{ margin: "auto", marginTop: 40 }}
      variant="rect"
      width={300}
      height={40}
    />
  );
}
