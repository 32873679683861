import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TeamCard from "components/TeamCard/TeamCard.js";
import SlickCarousel from "react-slick";
import TeamCardSkeleton from "components/TeamCard/TeamCardSkeleton.js";
import React from "react";
import style from "./ourTeamStyle.js";
import api from "services/api.js";

const useStyles = makeStyles(style);

export default function OurTeam() {
  const [loading, setLoading] = React.useState(true);
  const [team, setTeam] = React.useState(null);
  const [, forceRerender] = React.useState(0);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.addEventListener("resize", () => forceRerender((n) => !n));
    (async () => {
      try {
        setLoading(true);
        const response = (await api.getOurTeam()).data.data;
        setTeam(response);
        setLoading(false);
      } catch (err) {
        // setLoading(false);
      }
    })();
    return () => {
      window.removeEventListener("resize", () => forceRerender((n) => !n));
    };
  }, []);

  let teamElements = [];

  if (team) {
    team.forEach((member, index) => {
      teamElements.push(<TeamCard key={index} member={member} />);
    });
  }

  const classes = useStyles();
  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <div className={classes.ourTeamSection}>
            <h3 className={classes.ourTeamHeader}>Our Team</h3>
            {loading ? (
              <div style={{ backgroundColor: "#fffa" }}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={3} className={classes.mAuto}>
                    <TeamCardSkeleton />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3} className={classes.mAuto}>
                    <TeamCardSkeleton center />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3} className={classes.mAuto}>
                    <TeamCardSkeleton />
                  </GridItem>
                </GridContainer>
              </div>
            ) : (
              // <GridContainer justify="center">
              //   <GridItem xs={12} sm={12} md={3} className={classes.mAuto}>
              //     <TeamCard />
              //   </GridItem>
              //   <GridItem xs={12} sm={12} md={3} className={classes.mAuto}>
              //     <TeamCard big />
              //   </GridItem>
              //   <GridItem xs={12} sm={12} md={3} className={classes.mAuto}>
              //     <TeamCard />
              //   </GridItem>
              // </GridContainer>
              <GridContainer className={classes.teamContainer}>
                <GridItem>
                  <SlickCarousel
                    {...{
                      dots: true,
                      infinite: true,
                      speed: 600,
                      slidesToShow:
                        window.innerWidth > 1335
                          ? Math.min(3, teamElements?.length)
                          : window.innerWidth > 959
                          ? Math.min(2, teamElements?.length)
                          : 1,
                      slidesToScroll: 1,
                      autoplay: true,
                      autoplaySpeed: 4000,
                      arrows: true,
                    }}
                  >
                    {teamElements}
                  </SlickCarousel>
                </GridItem>
              </GridContainer>
            )}
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
