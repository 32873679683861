import {
  container,
  mlAuto,
  mrAuto,
} from "assets/jss/material-kit-pro-react.js";

const previousCoursesStyle = (theme) => ({
  mrAuto,
  mlAuto,

  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  root: {
    padding: "0px 0px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "60px 0px",
    },
  },
  container: {
    ...container,
    zIndex: 1,
  },
  title: {
    fontSize: "24px",
    color: "#2f2f2f",
    margin: "30px 0px",
    marginTop: 0,
    fontWeight: "bold",
  },
  shadowContainer: {
    width: "90%",
    height: "100%",
    marginTop: 20,
    marginBottom: 60,
    marginRight: "auto",
    marginLeft: "auto",
    padding: "48px 40px 48px",
    objectFit: "contain",
    borderRadius: "32.5px",
    boxShadow: "0 2.5px 25px 0 rgba(0, 0, 0, 0.5)",
    backgroundColor: "var(--very-light-pink)",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px",
      boxShadow: "none",
      width: "100%",
      borderRadius: 0,
      backgroundImage: "none",
    },
  },
  coursesHeaderImage: {
    width: "100%",
    margin: "auto",
    paddingBottom: "40.5px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pageTitle: {
    textAlign: "center",
    opacity: "0.75",
    fontWeight: "bold",
    color: "#2f2f2f",
    fontSize: "28px",
    paddingTop: 60,
    paddingBottom: 60,
  },
  paymentSuccessImg: {
    width: "78.5px",
    height: "59.7px",
    margin: "auto",
    display: "block",
    objectFit: "contain",
  },
  cell: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "right",
      display: "block",
    },
  },
});

export default previousCoursesStyle;
