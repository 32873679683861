import strings from "constants/strings";
import validator from "validator";

export const checkValidity = (value, rules) => {
  let isValid = true;
  let error = null;

  if (rules.required) {
    isValid = value.trim() !== "";
    error = !isValid ? "Required" : error;
  }

  if (rules.number && isValid) {
    isValid = !isNaN(value);
    error = !isValid ? "Not a valid number" : error;
  }

  if (rules.minLength && isValid) {
    isValid = value.length >= rules.minLength;
    error = !isValid
      ? rules.number
        ? "At least %min% numbers"
        : "At least %min% characters"
      : error;
    if (error) {
      error = error.replace("%min%", rules.minLength);
      error = error.replace("%min%", rules.minLength);
    }
  }

  if (rules.maxLength && isValid) {
    isValid = value.length <= rules.maxLength;
    error = !isValid
      ? rules.number
        ? "At Maximum %max% numbers"
        : "Maximum %max% characters"
      : error;
    if (error) {
      error = error.replace("%max%", rules.maxLength);
      error = error.replace("%max%", rules.maxLength);
    }
  }

  if (rules.phone && isValid) {
    isValid = validator.isMobilePhone(value);
    error = !isValid ? "Not a valid phone number" : error;
  }

  if (rules.email && isValid) {
    var emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    isValid = emailRegEx.test(value.toLowerCase());
    error = !isValid ? "Not a valid email" : error;
  }

  if (rules.password && isValid) {
    var passwordRegEx = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{8,}$/;
    isValid = passwordRegEx.test(value);
    error = !isValid ? "Password should follow the above rules" : error;
  }

  return error;
};

export const getDateString = (date) => {
  return (
    strings.shortWeekDays[date.getDay()] +
    ", " +
    date.getDate().toString() +
    "/" +
    // strings.months[date.getMonth()] +
    (date.getMonth() +
    1) +
    "/" +
    date.getFullYear().toString()
  );
};

export const formatAMPM = (timeString) => {
  var hours = parseInt(timeString.split(":")[0]);
  var minutes = parseInt(timeString.split(":")[1]);
  var ampm = "";
  ampm = hours >= 12 ? "pm" : "am";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;

  var strTime = hours.toString() + ":" + minutes.toString() + ampm;
  return strTime;
};

export const zeroPadding = (number, length = 6) =>
  (Array(length).join("0") + number).slice(-length);

export const msToTime = (s) => {
  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  return mins + ":" + zeroPadding(secs, 2);
};
