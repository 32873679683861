const clientLogoStyle = (theme) => ({
  clientCard: {
    maxWidth: "650px",
    margin: "60px auto",
    height: 300,
  },
  clientLogo: {
    color: "inherit",
    maxHeight: 200,
    maxWidth: 200,
    objectFit: "contain",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "50vw",
    },
  },
  clientLogoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 500,
    maxHeight: 300,
    margin: "40px auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "50vw",
    },
  },
});

export default clientLogoStyle;
