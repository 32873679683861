import { makeStyles } from "@material-ui/core/styles";
import CourseCard from "components/CourseCard/CourseCard.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import SlickCarousel from "react-slick";
import style from "./allCoursesListStyle.js";
import Pagination from "components/Pagination/Pagination.js";

const useStyles = makeStyles(style);

export default function AllCoursesList(props) {
  const { courses } = props;
  const [, forceRerender] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.addEventListener("resize", () => forceRerender((n) => !n));
    return () => {
      window.removeEventListener("resize", () => forceRerender((n) => !n));
    };
  }, []);

  let allCoursesElements = [];

  if (courses.length > 0) {
    courses.forEach((course, index) => {
      allCoursesElements.push(<CourseCard mAuto key={index} course={course} />);
    });
  }
  let matchingCourses = courses;
  let currentPageContent = courses;

  const indexOfLastCourse = currentPage * 3;
  const indexOfFirstCourse = indexOfLastCourse - 3;
  currentPageContent = matchingCourses.slice(
    indexOfFirstCourse,
    indexOfLastCourse
  );
  let currentPageContentElements = [];
  if (currentPageContent.length > 0) {
    currentPageContent.forEach((course, index) => {
      currentPageContentElements.push(
        <CourseCard key={index} course={course} />
      );
    });
  }

  const classes = useStyles();
  return window.innerWidth > 959 ? (
    <div className={classes.coursesContainer}>
      <SlickCarousel
        {...{
          dots: false,
          infinite: true,
          speed: 600,
          slidesToShow:
            window.innerWidth > 1335
              ? Math.min(3, allCoursesElements?.length)
              : window.innerWidth > 959
              ? Math.min(2, allCoursesElements?.length)
              : 1,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 4000,
          arrows: true,
        }}
      >
        {allCoursesElements}
      </SlickCarousel>
    </div>
  ) : (
    <GridContainer className={classes.coursesContainer}>
      {currentPageContentElements}
      <div className={classes.paginationContainer}>
        <Pagination
          color="primary"
          postsPerPage={3}
          totalPosts={courses.length}
          paginate={(page) => setCurrentPage(page)}
          currentPage={currentPage}
        />
      </div>
    </GridContainer>
  );
}
