import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import React from "react";
import style from "./testimonialCardStyle.js";
import defaultPerson from "assets/images/default-person.png";

const useStyles = makeStyles(style);

export default function TestimonialCard(props) {
  const { testimonial } = props;
  const classes = useStyles();
  return (
    <div>
      <Card testimonial plain className={classes.testimonialCard}>
        <CardAvatar testimonial plain>
          {testimonial?.imgUrl === null ? (
            <img src={defaultPerson} alt={testimonial?.name} />
          ) : (
            <img src={testimonial?.imgUrl} alt={testimonial?.name} />
          )}
        </CardAvatar>
        <CardBody plain>
          <h4 className={classes.cardTitle}>{testimonial?.name}</h4>
          {testimonial?.courseName ? (
            <h6 className={classes.job}>
              {testimonial.courseName + " " + "course"}
            </h6>
          ) : null}
          <p className={classes.cardDescription}>{testimonial?.comment}</p>
          <h6 className={classes.job}>{testimonial?.title}</h6>
          <h6 className={classes.job}>{testimonial?.company}</h6>
        </CardBody>
      </Card>
    </div>
  );
}
