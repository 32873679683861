import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  createMuiTheme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import classNames from "classnames";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PhoneInput from "components/TextInputs/PhoneInput/PhoneInput";
import { countryPhoneCodes } from "constants/countryPhoneCodes";
import strings from "constants/strings";
import enums from "enums";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { tryAuth } from "store/actions";
import validator from "validator";
import styles from "./authenticationFormStyle";

const useStyles = makeStyles(styles);

const CssTextField = withStyles(styles(createMuiTheme()).cssTextField)(
  TextField
);

export default function AuthenticationForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const authRedirectPath = useSelector((state) => state.auth.authRedirectPath);

  const {
    authMode,
    handleAuthModeChange,
    handleCloseAuthModal,
    handleForgotPassword,
  } = props;

  const [loading, setLoading] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setValue,
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      phone: "971",
    },
  });

  const validationRules = {
    email: {
      required: "This field is required.",
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Email not valid",
      },
      maxLength: {
        value: 50,
        message: "Email cannot exceed 50 characters.",
      },
    },
    fullname: {
      required: "This field is required.",
      pattern: {
        value: /^(\w.+\s).+$/,
        message: "Please insert your full name",
      },
    },
    phone: {
      validate: {
        phone: (value) => {
          let country,
            countryCode = "";

          if (authMode === enums.AuthMode.SIGNUP) {
            country = countrySelect;
            countryCode = countryPhoneCodes[country].code
              .replace("+", "")
              .replace(/\-/g, "");
          }

          const phone = value.replace(/[^0-9]/g, "");

          return (
            (phone === "" || countryCode === phone
              ? true
              : validator.isMobilePhone(
                  `+${countryCode}${phone.replace(countryCode, "")}`
                )) || "Phone number not valid"
          );
        },
      },
    },
    password: {
      required: "This field is required.",
      minLength: {
        value: 8,
        message: "Password should be at least 8 characters.",
      },
      maxLength: {
        value: 60,
        message: "Password cannot exceed 60 characters.",
      },
    },
  };

  const handleClickShowPassword = () =>
    setShowPassword((prevShowPassword) => !prevShowPassword);

  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleAuthentication = async () => {
    try {
      setLoading(true);
      let country, countryCode;
      let hasPhone = false;

      if (authMode === enums.AuthMode.SIGNUP) {
        country = countrySelect;
        countryCode = countryPhoneCodes[country].code
          .replace("+", "")
          .replace(/\-/g, "");

        hasPhone =
          getValues("phone") !== "" && countryCode !== getValues("phone");
      }

      await dispatch(
        tryAuth(
          {
            email: getValues("email"),
            password: getValues("password"),
            ...(authMode === enums.AuthMode.SIGNUP
              ? { name: getValues("fullname") }
              : {}),
            ...(hasPhone
              ? {
                  countryCode: `+${countryCode}`,
                  phone: getValues("phone")
                    .replace(countryCode, "")
                    .replace(/[^0-9]/g, ""),
                  country,
                }
              : {}),
          },
          authMode
        )
      );

      handleCloseAuthModal();
      if (authMode === enums.AuthMode.SIGNUP) {
        history.push("auth/email/confirm");
      } else {
        history.push(location.pathname + location.search);
      }
    } catch (err) {
      setLoading(false);
      if (err.response?.data?.param) {
        setError(err.response.data.param, {
          type: "manual",
          message: err.response.data.message,
        });
      } else {
        console.log(err);
        console.log("Something went wrong!");
      }
    }
  };

  const [countrySelect, setCountrySelect] = React.useState(225);

  const signUpInfo = [
    <CssTextField
      id="fullname"
      key="fullname"
      name="fullname"
      error={errors.fullname?.message !== undefined}
      helperText={errors.fullname?.message}
      inputRef={register(validationRules.fullname)}
      label="Full name"
      variant="outlined"
      size="small"
      className={classes.textFieldMarginBottom}
      InputProps={{ className: classes.textField }}
    />,
    <Controller
      render={(props) => (
        <PhoneInput
          id="phone"
          error={errors.phone?.message !== undefined}
          helperText={errors.phone?.message}
          inputRef={register(validationRules.phone)}
          label="Phone (optional)"
          variant="outlined"
          size="small"
          value={props.value}
          setValue={setValue}
          country={countrySelect}
          setCountry={setCountrySelect}
          className={classes.phoneInput}
        />
      )}
      key="phone"
      name="phone"
      defaultValue=""
      control={control}
      rules={validationRules.phone}
    />,
  ];

  const formClasses = classNames({
    [classes.formContainer]: true,
    [classes.signInContainer]: authMode,
    [classes.signUpContainer]: !authMode,
  });

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(handleAuthentication)}
    >
      <GridContainer justify="center" className={classes.rootContainer}>
        <GridItem xs={12} sm={12} md={12} className={formClasses}>
          <h5 className={classes.signUpWithEmail}>
            {`${
              strings[authMode === enums.AuthMode.SIGNUP ? "signUp" : "signIn"]
            } ${strings.usingEmail}`}
          </h5>
          {authMode === enums.AuthMode.SIGNUP ? signUpInfo : null}

          <CssTextField
            id="email"
            name="email"
            error={errors.email?.message !== undefined}
            helperText={errors.email?.message}
            inputRef={register(validationRules.email)}
            label="Email"
            variant="outlined"
            size="small"
            className={classes.textFieldMarginBottom}
            InputProps={{ className: classes.textField }}
          />
          <CssTextField
            id="password"
            name="password"
            error={errors.password?.message !== undefined}
            type={showPassword ? "text" : "password"}
            helperText={errors.password?.message}
            inputRef={register(validationRules.password)}
            label="Password"
            variant="outlined"
            size="small"
            className={classes.textFieldMarginBottom}
            InputProps={{
              className: classes.textField,
              endAdornment:
                watch("password") !== "" ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ) : null,
            }}
          />

          {authMode === enums.AuthMode.LOGIN ? (
            <div
              className={classes.forgotPasswordContainer}
              onClick={handleForgotPassword}
            >
              Forgot password?
            </div>
          ) : null}

          <Button
            type="submit"
            className={classes.signUpButton}
            loading={loading}
          >
            {authMode === enums.AuthMode.SIGNUP
              ? strings.signUp
              : strings.signIn}
          </Button>
          <span className={classes.alreadyMember}>
            <span>
              {authMode === enums.AuthMode.SIGNUP
                ? strings.alreadyMember
                : strings.notMember}
            </span>
            &nbsp;
            <span>
              <a className={classes.link} onClick={handleAuthModeChange}>
                {authMode === enums.AuthMode.SIGNUP
                  ? strings.signIn
                  : strings.signUp}
              </a>
            </span>
          </span>
        </GridItem>
      </GridContainer>
    </form>
  );
}
