import { makeStyles } from "@material-ui/core/styles";
import Phone from "@material-ui/icons/Phone";
import PinDrop from "@material-ui/icons/PinDrop";
import flags from "assets/images/flags.png";
import worldMap from "assets/images/map-of-world.png";
import reviewImage from "assets/images/reviewImage.png";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import React from "react";
import api from "services/api.js";
import { checkValidity } from "utils/index.js";
import style from "./contactUsStyle.js";

const useStyles = makeStyles(style);

export default function ContactUs() {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    name: {
      value: "",
      error: null,
      validation: {
        required: true,
        minLength: 1,
        maxLength: 100,
      },
    },
    email: {
      value: "",
      error: null,
      validation: {
        required: true,
        minLength: 1,
        maxLength: 100,
        email: true,
      },
    },
    phone: {
      value: "",
      validation: {
        required: true,
        // phone: true,
      },
      valid: false,
      error: null,
    },
    message: {
      value: "",
      error: null,
      validation: {
        required: true,
        minLength: 1,
        maxLength: 500,
      },
    },
  });
  const [loading, setLoading] = React.useState(false);
  const [
    responseMessageVisibleClass,
    setResponseMessageVisibleClass,
  ] = React.useState("");
  const [responseMessage, setresponseMessage] = React.useState("");

  const handleChange = (prop) => (event) => {
    const updatedElement = { ...values[prop] };
    updatedElement.value = event.target.value;

    setValues({ ...values, [prop]: updatedElement });
  };

  const handleSubmit = async () => {
    let isFormValid = true;
    let updatedValues = { ...values };
    Object.keys(updatedValues).forEach((key) => {
      const updatedElement = { ...updatedValues[key] };
      updatedElement.error = checkValidity(
        updatedElement.value,
        updatedElement.validation
      );
      updatedValues = { ...updatedValues, [key]: updatedElement };
      isFormValid = isFormValid && (updatedElement.error ? false : true);
    });
    setValues(updatedValues);
    if (isFormValid) {
      try {
        setLoading(true);
        const subscribeResponse = await api.contactUs({
          name: values.name.value,
          email: values.email.value,
          phone: values.phone.value,
          message: values.message.value,
        });
        setLoading(false);
        setresponseMessage(
          "Thank you for contacting us, we will get back to you shorlty."
        );
        setTimeout(() => {
          setResponseMessageVisibleClass(classes.responseMessageVisible);
        }, 1000);
        setTimeout(() => {
          setResponseMessageVisibleClass("");
        }, 5000);
      } catch (err) {
        setresponseMessage(err.response.data.message);
        setLoading(false);
        setTimeout(() => {
          setResponseMessageVisibleClass(classes.responseMessageVisible);
        }, 1000);
        setTimeout(() => {
          setResponseMessageVisibleClass("");
        }, 5000);
      }
    }
  };

  return (
    <div className={classes.contactRoot}>
      <div className={classes.container}>
        <h3 className={classes.contactHeader}>Contact Us</h3>
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={6}
            className={classes.locations + " " + classes.mAuto}
          >
            <div className={classes.contactUnderlinedHeaderContainer}>
              <h3
                className={classes.contactUnderlinedHeaderText}
                style={{ margin: 0 }}
              >
                Locations
              </h3>
              <span className={classes.contactHeaderUnderline} />
            </div>
            <img src={worldMap} className={classes.worldMap} alt="worldMap" />
            <div className={classes.flagsContainer}>
              <img src={flags} className={classes.flags} alt="flags" />
            </div>
            <div className={classes.reviewImageContainer}>
              <img
                src={reviewImage}
                className={classes.reviewImage}
                alt="reviewImage"
              />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className={classes.contactForm}>
            <div
              className={
                classes.contactUnderlinedHeaderContainer +
                " " +
                classes.displayNoneMobile
              }
            >
              <h3
                className={classes.contactUnderlinedHeaderText}
                style={{ margin: 0 }}
              >
                Contact Form
              </h3>
              <span className={classes.contactHeaderUnderline} />
            </div>
            <p className={classes.thankYouText}>
              Thank you for contacting Grey Matter U! Please take the time to
              fill out the contact form below
              <br />
              <br />
            </p>
            <form>
              <CustomInput
                error={values.name.error ? true : false}
                value={values.name.value}
                inputProps={{
                  onChange: handleChange("name"),
                }}
                labelText="Your Name"
                id="float"
                formControlProps={{
                  fullWidth: true,
                }}
              />
              <CustomInput
                error={values.email.error ? true : false}
                value={values.email.value}
                inputProps={{
                  onChange: handleChange("email"),
                }}
                labelText="Email address"
                id="float"
                formControlProps={{
                  fullWidth: true,
                }}
              />
              <CustomInput
                error={values.phone.error ? true : false}
                value={values.phone.value}
                inputProps={{
                  onChange: handleChange("phone"),
                }}
                labelText="Phone"
                id="float"
                formControlProps={{
                  fullWidth: true,
                }}
              />
              <CustomInput
                error={values.message.error ? true : false}
                value={values.message.value}
                inputProps={{
                  onChange: handleChange("message"),
                  multiline: true,
                  rows: 6,
                }}
                labelText="Your message"
                id="float"
                formControlProps={{
                  fullWidth: true,
                }}
              />
              <div className={classes.textCenter}>
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  className={classes.button}
                  round
                  loading={loading}
                >
                  Contact us
                </Button>
              </div>
              <div
                className={
                  classes.responseMessage + " " + responseMessageVisibleClass
                }
              >
                {responseMessage}
              </div>
            </form>
            <div className={classes.infoAreaContainer}>
              <InfoArea
                className={classes.info}
                title="Find us at the office"
                description={
                  <ul style={{ marginLeft: -27 }}>
                    {/* <p>
                      Bld Mihail Kogalniceanu, nr. 8, <br /> 7652 Bucharest,{" "}
                      <br /> Romania
                    </p> */}
                    {/* <li style={{ marginBottom: 17 }}>
                      Harju maakond, Kuusalu vald, Pudisoo küla, Männimäe/1,
                      74626 */}
                      {/* <br /> Romania */}
                    {/* </li> */}
                    <li>
                      Grey Matter LLC. Dubai World Central, Dubai, PO Box
                      -712165
                      {/* <br /> Romania */}
                    </li>
                  </ul>
                }
                icon={PinDrop}
                iconColor="primary"
              />
              {/* <InfoArea
                className={classes.info}
                title="Give us a ring"
                description={
                  <p>
                    Michael Jordan <br /> +40 762 321 762 <br /> Mon - Fri,
                    8:00-22:00
                  </p>
                }
                icon={Phone}
                iconColor="primary"
              /> */}
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
