import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React from "react";
import Cropper from "react-easy-crop";
import styles from "./customInputStyle";

const useStyles = makeStyles(styles);

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return canvas.toDataURL("image/jpeg");
}

export default function ImageUpload(props) {
  let { className, imageData, handleSave, loading } = props;

  const [file, setFile] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [aspect, setAspect] = React.useState(4 / 4);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);

  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(null);

  // React.useEffect(() => {
  //   setImage(imageData);
  //   setImagePreviewUrl(imageData ? imageData : defaultImage);
  // }, [imageData]);

  let fileInput = React.createRef();

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
      if (props.onChange) {
        props.onChange(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };
  // eslint-disable-next-line
  const handleSubmit = (e) => {
    e.preventDefault();
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    if (props.onChange) {
      props.onChange(null);
    }
    setFile(null);
    setImagePreviewUrl(null);
    fileInput.current.value = null;
  };
  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    try {
      const croppedImage = await getCroppedImg(
        imagePreviewUrl,
        croppedAreaPixels
      );
      props.onChange(croppedImage);
    } catch (e) {
      props.onChange(null);
    }
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const save = () => {
    handleSave();
  };

  const classes = useStyles();
  let rootClasses = "fileinput text-center " + className;
  return (
    <div className={rootClasses}>
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div className={classes.imgContainer}>
        {imagePreviewUrl === null ? (
          <div
            onClick={() => handleClick()}
            className={classes.dropHereContainer}
          >
            Click here to attach or drop files
          </div>
        ) : (
          <div className={classes.cropContainer}>
            <Cropper
              image={imagePreviewUrl}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onCropChange={onCropChange}
              onCropComplete={onCropComplete}
              onZoomChange={onZoomChange}
              // onMediaLoaded={(mediaSize) => {
              //   onZoomChange(300 / mediaSize.naturalHeight);
              // }}
              cropShape={"round"}
            />
          </div>
        )}
        {imagePreviewUrl === null ? null : (
          <RemoveCircleIcon
            className={classes.removeIcon}
            onClick={() => handleRemove()}
          />
        )}
      </div>
      {imagePreviewUrl === null ? null : (
        <GridContainer className={classes.controls}>
          <GridItem xs={4} sm={4} md={4} className={classes.flexCenter}>
            Zoom:
          </GridItem>
          <GridItem xs={7} sm={7} md={7} className={classes.flexCenter}>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(zoom)}
              classes={{ container: "slider" }}
            />
          </GridItem>
        </GridContainer>
      )}
      <Button
        onClick={save}
        color="primary"
        className={classes.button}
        loading={loading}
      >
        Save
      </Button>
    </div>
  );
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  // it is a function from which you can get the files and fileNames that were uploaded
  // more can be read here: https://github.com/creativetimofficial/ct-material-kit-pro-react/issues/64
  onChange: PropTypes.func,
};
