import { makeStyles } from "@material-ui/core/styles";
import collage from "assets/images/collage.png";
import person from "assets/images/confused-person.png";
import primaryAntenna from "assets/images/primary-antenna.png";
import primaryGear from "assets/images/primary-gear.png";
import primaryMic from "assets/images/primary-mic.png";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import style from "./whoWeAreStyle.js";

const useStyles = makeStyles(style);

export default function WhoWeAre() {
  const classes = useStyles();
  return (
    <div className={classes.whoWeAreSection}>
      <div className={classes.infoHeader}>
        <h3 className={classes.whoWeAreHeader}>Who we are</h3>
        <span className={classes.whoWeAreUnderline} />
      </div>
      <div className={classes.whoWeAreTopSection}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4} className={classes.infoSingle}>
            <img
              className={classes.infoImage}
              height={90}
              width={90}
              src={primaryGear}
            />
            <div className={classes.infoHeader}>
              <h3 style={{ fontWeight: "bold", margin: 0 }}>A perfect fit!</h3>
              <span className={classes.headerUnderline} />
            </div>
            <p style={{ textAlign: "center", marginTop: 30 }}>
              Our courses are tailored to suit your specific needs and
              preferences.
            </p>
          </GridItem>
          <GridItem xs={12} sm={12} md={4} className={classes.infoSingle}>
            <img className={classes.infoImage} height={90} src={primaryMic} />
            <div className={classes.infoHeader}>
              <h3 style={{ fontWeight: "bold", margin: 0 }}>A tight ship</h3>
              <span className={classes.headerUnderline} />
            </div>
            <p style={{ textAlign: "center", marginTop: 30 }}>
              If it’s scheduled, it’s going to happen. No more cancellations or
              delays!
            </p>
          </GridItem>
          <GridItem xs={12} sm={12} md={4} className={classes.infoSingle}>
            <img
              className={classes.infoImage}
              height={90}
              width={90}
              src={primaryAntenna}
            />
            <div className={classes.infoHeader}>
              <h3 style={{ fontWeight: "bold", margin: 0 }}>No fluff!</h3>
              <span className={classes.headerUnderline} />
            </div>
            <p style={{ textAlign: "center", marginTop: 30 }}>
              Our courses are direct and concise with practical information that
              can be applied right away.
            </p>
          </GridItem>
        </GridContainer>
      </div>
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={5} style={{ display: "flex" }}>
            <img className={classes.person} src={person} />
          </GridItem>
          <GridItem xs={12} sm={12} md={7}>
            <img className={classes.collage} src={collage} />
            <p className={classes.collageDescriptoin}>
              Grey Matter was founded in 2014 with a mission to redefine the
              luxury Consulting & Training services to focus on delivering
              client results, not just reports or theory. We have continued to
              be innovators for our industry, with an entrepreneurial culture
              that is never satisfied with the status quo, for ourselves or for
              our clients. Grey Matter grew from 1 employee to 5 full timers and
              24 associates around the globe.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
