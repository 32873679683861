import { makeStyles } from "@material-ui/core/styles";
import kais from "assets/images/kais.png";
import classNames from "classnames";
import ContactUs from "components/ContactUs/ContactUs.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import OurClients from "components/OurClients/OurClients.js";
import Parallax from "components/Parallax/Parallax.js";
import React from "react";
import api from "services/api.js";
import { checkValidity } from "utils/index.js";
import style from "./homeStyle.js";
import Info from "./Info/Info";

const useStyles = makeStyles(style);

export default function Home() {
  const [email, setEmail] = React.useState({
    value: "",
    error: null,
    validation: {
      required: true,
      minLength: 1,
      maxLength: 100,
      email: true,
    },
  });
  const [emailLoading, setEmailLoading] = React.useState(false);
  const [
    subscribeMessageVisibleClass,
    setSubscribeMessageVisibleClass,
  ] = React.useState("");
  const [
    subscribeResponseMessage,
    setSubscribeResponseMessage,
  ] = React.useState(".");

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const classes = useStyles();
  const handleSubscribe = async () => {
    let updatedElement = { ...email };
    updatedElement.error = checkValidity(
      updatedElement.value,
      updatedElement.validation
    );
    setEmail(updatedElement);
    if (!updatedElement.error) {
      setEmailLoading(!emailLoading);
      try {
        setEmailLoading(true);
        const subscribeResponse = await api.subscribeToNewsletter(email.value);
        setEmailLoading(false);
        setSubscribeResponseMessage("Thank you for subscribing");
        setTimeout(() => {
          setSubscribeMessageVisibleClass(
            classes.subscribeSuccessMessageVisible
          );
        }, 1000);
        setTimeout(() => {
          setSubscribeMessageVisibleClass("");
        }, 5000);
      } catch (err) {
        setSubscribeResponseMessage(err.response.data.message);
        setEmailLoading(false);
        setTimeout(() => {
          setSubscribeMessageVisibleClass(
            classes.subscribeSuccessMessageVisible
          );
        }, 1000);
        setTimeout(() => {
          setSubscribeMessageVisibleClass("");
        }, 5000);
      }
    }
  };

  const handleEmailChange = (event) => {
    const updatedEmailElement = { ...email };
    updatedEmailElement.value = event.target.value;
    setEmail(updatedEmailElement);
  };

  return (
    <div>
      {/* <Parallax image={require("assets/images/home-background.png")}> */}
      <Parallax
        // image={require("assets/images/home-background.jpg")}
        className={classes.parallax}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem md={8} sm={8} className={classNames(classes.mrAuto)}>
              {/* <h3 style={{ fontWeight: "bold", color: "white" }}>
                U in the heart of ed-U-cation
              </h3> */}
              <h1
                style={{ fontWeight: "bold", marginBottom: 30, color: "white" }}
              >
                Putting U in the Heart of Edu
              </h1>
              <p className={classes.homeParagraph}>
                Realize your potential <br className={classes.break} /> With the
                world's best teachers working to give U the most effective resources
              </p>
              <h3 style={{ fontWeight: "bold", color: "white" }}>
                Grey Matter U
              </h3>
              <p className={classes.homeParagraph}>
                {/* The world's best teachers working to give U effective methods
                and tools and work.
                <br />
                <br />  */}
                A better world starts with a better U.
              </p>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.root)}>
        <div className={classes.container + " " + classes.shadowContainer}>
          <Info />
          <div className={classes.banner1Container}>
            <div className={classes.banner + " " + classes.banner1Image}>
              <div className={classes.container}>
                <GridContainer justify="center">
                  <GridItem
                    xs={12}
                    sm={12}
                    md={7}
                    className={classes.stayInTouch}
                  >
                    <h3 className={classes.stayInTouchHeader}>Stay in touch</h3>
                    <h6 className={classes.stayInTouchText}>
                      Sign up for our newsletter to stay up to date and receive
                      special offers!
                    </h6>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}></GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={3}
                    className={classes.subscribeForm}
                  >
                    <CustomInput
                      error={email.error ? true : false}
                      inputProps={{
                        placeholder: "Email",
                        onChange: handleEmailChange,
                      }}
                      filled
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    <Button
                      round
                      color="primary"
                      className={classes.button + " " + classes.subscribe}
                      onClick={handleSubscribe}
                      loading={emailLoading}
                    >
                      Subscribe
                    </Button>
                    <div
                      className={
                        classes.subscribeSuccessMessage +
                        " " +
                        subscribeMessageVisibleClass
                      }
                    >
                      {subscribeResponseMessage}
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
          <OurClients />
          <div className={classes.banner2Container}>
            <div className={classes.banner + " " + classes.banner1Image}>
              <div className={classes.container + " " + classes.kaisBanner}>
                <h3 className={classes.kaisQuote}>
                  '' I wanted to create a learning experience that has learning
                  and experience ''
                </h3>
                <h4 className={classes.kaisText}>
                  Kais Badran, founder and CEO Grey Matter Group
                  {/* <br /> */}
                  
                </h4>
                <img className={classes.kais} src={kais} />
              </div>
            </div>
          </div>
          <ContactUs />
        </div>
      </div>
    </div>
  );
}
