import { makeStyles } from "@material-ui/core/styles";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { Rating } from "@material-ui/lab";
import defaultCourse from "assets/images/default-course.png";
import timer from "assets/images/timer.png";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import style from "./courseCardStyle.js";

const useStyles = makeStyles(style);

export default function CourseCard(props) {
  const classes = useStyles();
  const history = useHistory();

  const { long, mAuto, course } = props;
  const rootClasses = classNames({
    [classes.mAuto]: mAuto,
    [classes.root]: true,
  });

  const imageContainerClasses = classNames({
    [classes.longCard]: long,
    [classes.imageContainer]: true,
  });

  const goToCourse = () => {
    history.push({
      pathname: "course",
      search: `?courseId=${course?.id}`,
    });
  };
  let totalMinutes = course?.totalDuration ? course.totalDuration : 0;
  let hours = Math.floor(totalMinutes / 60);
  let minutes = totalMinutes % 60;

  let duration = (hours > 0 ? hours + "h " : "") + minutes + "m";

  return (
    <div className={rootClasses}>
      <div className={classes.container}>
        <Link to={`/course?courseId=${course?.id}`}>
          <div className={imageContainerClasses}>
            {course?.imgUrl ? (
              <img className={classes.image} src={course?.imgUrl} alt="img1" />
            ) : (
              <img className={classes.image} src={defaultCourse} alt="img1" />
            )}
          </div>{" "}
        </Link>
        <div className={classes.infoContainer}>
          <Link to={`/course?courseId=${course?.id}`}>
            <h6 className={classes.courseTitle}>{course?.name}</h6>
          </Link>
          <div className={classes.ratingContainer}>
            {/* <span className={classes.rating}>4.5</span>
            <Rating
              name="courseRating"
              precision={0.1}
              value={4.5}
              readOnly
              icon={
                <StarIcon className={classes.ratingStar} fontSize="inherit" />
              }
              emptyIcon={
                <StarBorderIcon
                  className={classes.ratingStar}
                  fontSize="inherit"
                />
              }
            />
            <span className={classes.ratingCount}>(433)</span> */}
          </div>
          <span className={classes.text}>${course?.price}</span>
          <div className={classes.cardFooter}>
            <img className={classes.timerIcon} src={timer} alt="timer" />
            <span className={classes.text}>
              {course?.durationString ? course?.durationString : duration}
            </span>
            <Button
              color="primary"
              className={classes.bookButton}
              onClick={goToCourse}
            >
              {course?.enrolled ? "Details" : "View Course"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
