import { makeStyles } from "@material-ui/core/styles";
import authLowerBorder from "assets/images/auth-lower-border.png";
import authUpperBorder from "assets/images/auth-upper-border.png";
import secondaryLogo from "assets/images/secondary-logo.png";
import Button from "components/CustomButtons/Button";
import CustomModal from "components/CustomModals/CustomModal/CustomModal";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import AuthenticationForm from "components/Forms/AuthenticationForm/AuthenticationForm";
import ForgotPassword from "components/Forms/ForgotPassword/ForgotPassword";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./authModalStyle";

const useStyles = makeStyles(styles);

const SocialButton = (props) => (
  <a
    href={`${
      process.env.REACT_APP_API_HOST
    }/students/${props.provider.toLowerCase()}`}
    style={{ width: "90%" }}
  >
    <Button
      color="white"
      target="_blank"
      className={props.classes.buttonOutlined}
      adornment
      round
      onClick={props.onClick}
    >
      <i
        className={
          `fab fa-${props.provider.toLowerCase()} ` +
          props.classes[`fa${props.provider}`] +
          " " +
          props.classes.socials
        }
      />
      {`Continue with ${props.provider.toLowerCase()}`}
    </Button>
  </a>
);

export default function AuthModal(props) {
  const classes = useStyles();
  const {
    open,
    onClose,
    children,
    style,
    authMode,
    handleAuthModeChange,
  } = props;

  const [showForgotPasswordForm, setShowForgotPasswordForm] = React.useState(
    false
  );

  return (
    <CustomModal open={open} onClose={onClose}>
      <GridContainer justify="center" className={classes.authModalContainer}>
        <GridItem
          className={classes.noPadding + " " + classes.authModalInfo}
          xs={12}
          sm={6}
          md={6}
        >
          <div className={classes.authModalInfoContainer}>
            <img
              src={secondaryLogo}
              alt="Grey Matter Logo"
              className={classes.logo}
            />

            <h3 className={classes.authModalInfoTitle}>
              Join GreymatterU for Free
            </h3>

            <div className={classes.authModalBorderContainer}>
              <img src={authUpperBorder} className={classes.authUpperBorder} />
              <img src={authLowerBorder} className={classes.authLowerBorder} />
            </div>

            <h4 className={classes.authModalInfoParagraph}>
              Bridge your weaknesses and super-charge your performance with our
              online training programs!
            </h4>
          </div>
        </GridItem>
        <GridItem
          className={classes.noPadding + " " + classes.authModalBody}
          xs={12}
          sm={6}
          md={6}
        >
          {showForgotPasswordForm ? (
            <ForgotPassword />
          ) : (
            <div className={classes.authModalBodyContainer}>
              <SocialButton provider="Google" classes={classes} />
              <SocialButton provider="Facebook" classes={classes} />
              <SocialButton provider="Twitter" classes={classes} />
              <div className={classes.orBorderContainer}>
                <div className={classes.orBorder} />
                <p className={classes.orParagraph}>or</p>
                <div className={classes.orBorder} />
              </div>

              <AuthenticationForm
                authMode={authMode}
                handleAuthModeChange={handleAuthModeChange}
                handleCloseAuthModal={onClose}
                handleForgotPassword={() => setShowForgotPasswordForm(true)}
              />

              <div className={classes.border} />
              <div className={classes.infoArea}>
                By signing up you agree to itsgreymatter's&nbsp;
                <br />
                <Link to="/termsofuse" className={classes.link}>
                  Terms of Service
                </Link>
                &nbsp;and &nbsp;
                <Link to="/privacypolicy" className={classes.link}>
                  Privacy Policy
                </Link>
              </div>
            </div>
          )}
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}
