import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import courseImage1 from "assets/images/kais_small.png";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import style from "./courseDetailsStyle.js";
const useStyles = makeStyles(style);

export default function CourseDetailsSkeleton(props) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.courseDataContainer}>
        <GridContainer>
          <GridItem sm={8} md={8}>
            <Skeleton variant="rect">
              <h2 className={classes.title}>Course Name</h2>
            </Skeleton>
            <Skeleton variant="rect">
              <div className={classes.text}>
                In this course you will engage in a series of challenges
                designed to increase your own happiness and build more
                productive habits. As preparation for these tasks, Professor
                Laurie Santos reveals misconceptions about happiness, annoying
                features of the mind that lead us to think the way we do, and
                the research that can help us change. You will ultimately be
                prepared to successfully incorporate a specific wellness
                activity into your life.
              </div>
            </Skeleton>
          </GridItem>
          <GridItem sm={4} md={4}>
            <Skeleton
              style={{ margin: "auto", marginBottom: 60 }}
              height={200}
              width={250}
              variant="rect"
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem sm={12} md={8}>
            <Skeleton style={{ marginTop: 40 }} variant="rect">
              <div className={classes.title}>Course Dates</div>
            </Skeleton>
            <Skeleton variant="rect">
              <div className={classes.text}>
                In this course you will engage in a series of challenges
                designed to increase your own happiness and build more
                productive habits. As preparation for these tasks, Professor
                Laurie Santos reveals misconceptions about happiness, annoying
                features of the mind that lead us to think the way we do, and
                the research that can help us change. You will ultimately be
                prepared to successfully incorporate a specific wellness
                activity into your life.
              </div>
            </Skeleton>
          </GridItem>
          <GridItem sm={12} md={4}>
            <Skeleton
              style={{ margin: "auto" }}
              height={500}
              width={250}
              variant="rect"
            />
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer className={classes.whatYouWilLearnContainer}>
        <GridItem sm={12} md={12}>
          <Skeleton variant="rect">
            <h2 className={classes.title}>What you will learn</h2>
          </Skeleton>
          <Skeleton variant="rect">
            <p className={classes.text}>
              In this course you will engage in a series of challenges designed
              to increase your own happiness and build more productive habits.
              As preparation for these tasks, Professor Laurie Santos reveals
              misconceptions about happiness, annoying features of the mind that
              lead us to think the way we do, and the research that can help us
              change. You will ultimately be prepared to successfully
              incorporate a specific wellness activity into your life.
            </p>
          </Skeleton>
        </GridItem>
      </GridContainer>
    </div>
  );
}
