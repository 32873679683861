import { makeStyles } from "@material-ui/core/styles";
import facebook from "assets/images/facebook.png";
import twitter from "assets/images/twitter.png";
import linkedin from "assets/images/linkedin.png";
import instagram from "assets/images/instagram.png";
import youtube from "assets/images/youtube.png";
import kais from "assets/images/about-kais.png";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import aboutKaisStyle from "./aboutKaisStyle.js";

const useStyles = makeStyles(aboutKaisStyle);

export default function AboutKais() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <h1 className={classes.pageTitle}>About Kais Badran</h1> */}
      <GridContainer>
        <GridItem sm={12} md={9} className={classes.aboutContainer}>
          {/* <div className={classes.subtitle}>Kais Badran Founder</div> */}
          <h1 className={classes.name}>
            Kais <br /> Badran.
          </h1>
          <div className={classes.subtitle}>Founder and CEO Grey Matter Group</div>
          <div>
            <p className={classes.text}>
              Kais is a consultant, coach and facilitator with 21 years of
              experience in the Learning & Development and luxury retail
              industries. Drawing on his involvement in leading projects
              worldwide, he uses his experience in coaching, retail, media and
              entrepreneurship to develop powerful programs that help companies
              and individuals achieve actionable growth.
            </p>
            <p className={classes.text}>
              Kais engages his audiences both emotionally and intellectually
              through presenting live experiences backed with strong visual
              illustrations and heartfelt stories.
            </p>
            <p className={classes.text}>
              He has trained thousands of people across 97 nationalities in 25
              countries, and catered to big luxury names including LVMH, Kering
              and Armani Group, in addition to many other Fortune 500 companies.
            </p>
            <p className={classes.text}>
              In addition to a BSc in Computer Engineering, Kais holds a Diploma
              in Movie Directing from the London Film Academy, and has
              diversified his personal portfolio to include General Studies in
              Improvise Comedy and Organization and relationship system
              coaching. He is also a qualified practitioner of the healing
              techniques NLP (Neuro-Linguistic Programming) and Hypnotherapy.
            </p>
          </div>
          <a href="https://www.facebook.com/greymatterufb" target="_blank">
            <img src={facebook} alt="facebook" className={classes.icon} />
          </a>
          <a href="https://www.instagram.com/greymatteru" target="_blank">
            <img src={instagram} alt="instagram" className={classes.icon} />
          </a>
          <a href="https://twitter.com/greymatterU1" target="_blank">
            <img src={twitter} alt="twitter" className={classes.icon} />
          </a>
          <a
            href="https://www.youtube.com/channel/UCNZJE7IBjJ7I9wegRI6vZUg"
            target="_blank"
          >
            <img src={youtube} alt="youtube" className={classes.icon} />
          </a>
          {/* <a href="https://www.linkedin.com" target="_blank">
            <img src={linkedin} alt="linkedin" className={classes.icon} />
          </a> */}
        </GridItem>
        <GridItem sm={12} md={3}>
          <img className={classes.image} src={kais}></img>
        </GridItem>
      </GridContainer>
    </div>
  );
}
