import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import strings from "constants/strings";
import React, { useEffect } from "react";
import termsAndConditionsPageStyle from "./termsAndConditionsStyle";

const useStyles = makeStyles(termsAndConditionsPageStyle);

export default function TermsAndConditions({ ...rest }) {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className={classes.pageHeader}>
        <GridContainer justify="center" className={classes.termsHeader}>
          <GridItem xs={12} sm={12} md={12}>
            <h1>{strings.termsAndConditions}</h1>
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.container}>
        {strings.termsAndConditionsContent.map((term, index) => (
          <GridContainer justify="center" key={`term-${index}`}>
            <GridItem xs={12} sm={12} md={12}>
              <h3 className={classes.termsHeading}>{term.title}</h3>
              <p className={classes.termBody}>{term.paragraph}</p>
            </GridItem>
          </GridContainer>
        ))}
      </div>
    </div>
  );
}
