import { makeStyles } from "@material-ui/core/styles";
import CustomModal from "components/CustomModals/CustomModal/CustomModal";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { useDispatch } from "react-redux";
import { uploadStudentImage } from "store/actions";
import styles from "./uploadImageModalStyle.js";

const useStyles = makeStyles(styles);

export default function UploadImageModal(props) {
  const classes = useStyles();
  const { open, onClose, imageUrl } = props;
  const [image, setImage] = React.useState(imageUrl);
  const [resetImageUploadFlag, setResetImageUploadFlag] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const onImageChange = (imageData) => {
    setImage(imageData);
  };

  React.useEffect(() => {
    if (!open) {
      setImage(null);
      setTimeout(() => {
        setResetImageUploadFlag(true);
      }, 500);
    } else {
      setResetImageUploadFlag(false);
    }
  }, [open]);

  const handleSave = async () => {
    try {
      setLoading(true);
      await dispatch(uploadStudentImage(image));
      setLoading(false);
      onClose();
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <CustomModal open={open} onClose={onClose}>
      <GridContainer justify="center">
        <GridItem className={classes.container}>
          {resetImageUploadFlag ? null : (
            <ImageUpload
              imageData={image}
              onChange={onImageChange}
              className={classes.imageUpload}
              addButtonProps={{ color: "primary" }}
              changeButtonProps={{ color: "warning" }}
              removeButtonProps={{ color: "danger" }}
              loading={loading}
              handleSave={handleSave}
            />
          )}
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}
