import {
  hexToRgb,
  primaryColor,
  whiteColor,
} from "assets/jss/material-kit-pro-react";

const forgotPasswordStyle = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  form: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  resetPasswordButton: {
    "& > span:first-child": {
      color: whiteColor,
    },
    padding: 0,
    height: 30,
    width: 260,
    backgroundColor: primaryColor[0],
    fontFamily: "sans-serif",
    fontSize: 13,
    fontWeight: "bold",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "rgba(" + hexToRgb(primaryColor[0]) + ", 0.8)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  formContainer: {
    transition: "0.3s all",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0px !important",
    },
  },
  textFieldMarginBottom: {
    marginBottom: 10,
    // width: "90%",
  },
  rootContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  textField: {
    width: 260,
    height: 35,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  cssTextField: {
    root: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "& label.MuiFormLabel-root": {
        marginTop: -3,
      },
      "& label.Mui-focused": {
        color: "#8c7e4c",
        marginTop: 0,
      },
      "& label.MuiFormLabel-filled": {
        marginTop: 0,
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#8c7e4c",
      },
      "& .MuiOutlinedInput-root": {
        //   "& fieldset": {
        //     borderColor: "white",
        //   },
        //   "&:hover fieldset": {
        //     borderColor: "white",
        //   },
        "&.Mui-focused fieldset": {
          borderColor: "#8c7e4c",
        },
      },
    },
  },
  enterEmailTitle: {
    width: "100%",
    marginBottom: 5,
    display: "flex",
    justifyContent: "flex-start",
    color: primaryColor[0],
    fontSize: 12,
  },
  resetPasswordTitle: {
    textAlign: "center",
    fontSize: 12,
    color: primaryColor[3],
    width: 260,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  email: {
    textAlign: "center",
    fontSize: 12,
    color: primaryColor[0],
  },
});

export default forgotPasswordStyle;
