import {
  hexToRgb,
  primaryColor,
  whiteColor,
} from "assets/jss/material-kit-pro-react";

const authenticationFormStyle = (theme) => ({
  form: { display: "flex", justifyContent: "center", width: "100%" },
  signUpButton: {
    "& > span:first-child": {
      color: whiteColor,
    },
    padding: 0,
    minHeight: 30,
    height: 30,
    width: 260,
    backgroundColor: primaryColor[0],
    fontFamily: "sans-serif",
    fontSize: 13,
    fontWeight: "bold",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "rgba(" + hexToRgb(primaryColor[0]) + ", 0.8)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  phoneNumber: {
    // "& label.Mui-focused": {
    //   color: "#8c7e4c",
    // },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },

    "& label.MuiFormLabel-root": {
      marginTop: -3,
    },
    "& label.Mui-focused": {
      color: "#8c7e4c",
      marginTop: 0,
    },
    "& label.MuiFormLabel-filled": {
      marginTop: 0,
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#8c7e4c",
    },
    "& .MuiOutlinedInput-root": {
      //   "& fieldset": {
      //     borderColor: "white",
      //   },
      //   "&:hover fieldset": {
      //     borderColor: "white",
      //   },
      width: 260,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      height: 35,
      "&.Mui-focused fieldset": {
        borderColor: "#8c7e4c",
      },
    },
  },
  phoneInput: {
    width: "100%",
  },
  signUpWithEmail: {
    textAlign: "center",
    fontSize: 14,
    marginTop: 0,
    color: primaryColor[0],
    fontWeight: "bold",
  },
  alreadyMember: {
    textAlign: "center",
    fontSize: 12,
    color: primaryColor[3],
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  link: {
    cursor: "pointer",
    color: primaryColor[0],
    fontWeight: "bold",
    "&:hover,&:focus": {
      textDecoration: "underline",
      color: primaryColor[0],
    },
  },
  formContainer: {
    transition: "0.3s all",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0px !important",
    },
  },
  signInContainer: {
    minHeight: 185,
    maxHeight: 235,
  },
  signUpContainer: {
    minHeight: 275,
    maxHeight: 370,
  },
  signUpInfo: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,

    // width: "90%",
  },
  textFieldMarginBottom: {
    marginBottom: 10,
    // width: "90%",
  },
  rootContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  namesTextField: {
    width: 125,
    height: 35,
    fontSize: 15,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textField: {
    width: 260,
    height: 35,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  cssTextField: {
    root: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "& label.MuiFormLabel-root": {
        marginTop: -3,
      },
      "& label.Mui-focused": {
        color: "#8c7e4c",
        marginTop: 0,
      },
      "& label.MuiFormLabel-filled": {
        marginTop: 0,
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#8c7e4c",
      },
      "& .MuiOutlinedInput-root": {
        //   "& fieldset": {
        //     borderColor: "white",
        //   },
        //   "&:hover fieldset": {
        //     borderColor: "white",
        //   },
        "&.Mui-focused fieldset": {
          borderColor: "#8c7e4c",
        },
      },
    },
  },
  nameInputContainer: {
    width: "47.5%",
    display: "flex",
    justifyContent: "center",
  },

  forgotPasswordContainer: {
    marginLeft: "auto",
    marginRight: 0,
    display: "flex",
    justifyContent: "flex-end",
    color: primaryColor[0],
    cursor: "pointer",
    fontSize: 12,
    "&:hover,&:focus": {
      textDecoration: "underline",
      color: primaryColor[0],
    },
  },
});

export default authenticationFormStyle;
