import { primaryColor } from "assets/jss/material-kit-pro-react";
import { mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.js";

const teamCardStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  teamCard: {
    maxWidth: "650px",
    margin: "60px auto",
    [theme.breakpoints.down("sm")]: {
      margin: "0px auto",
      marginTop: 40,
    },
  },
  cardDescription: {
    marginTop: 20,
  },
  cardTitle: {
    color: primaryColor[0],
  },
  job: {
    color: primaryColor[0],
  },
  avatar: {
    maxHeight: 170,
    maxWidth: 170,
    marginTop: "40px !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px !important",
      maxHeight: 150,
      maxWidth: 150,
    },
  },
  sideCard: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  avatarSkeleton: {
    maxHeight: 170,
    maxWidth: 170,
    [theme.breakpoints.down("sm")]: {
      maxHeight: 150,
      maxWidth: 150,
    },
  },
  centerAvatar: {
    maxHeight: 170,
    maxWidth: 170,
    [theme.breakpoints.down("sm")]: {
      maxHeight: 150,
      maxWidth: 150,
    },
  },
});

export default teamCardStyle;
