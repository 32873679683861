import {
  container,
  grayColor,
  hexToRgb,
  primaryColor,
  secondaryColor,
  whiteColor,
  mlAuto,
  mrAuto,
} from "assets/jss/material-kit-pro-react";
import awesomeSmile from "assets/images/awesome-smile.png";

const emailConfirmationStyle = (theme) => ({
  mrAuto,
  mlAuto,

  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  root: {
    padding: "0px 0px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "60px 0px",
    },
  },
  container: {
    ...container,
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      marginTop: 70,
    },
  },
  title: {
    fontSize: 14,
    marginTop: 15,
    color: secondaryColor[0],
    textAlign: "center",
  },
  emailTitle: {
    fontSize: 14,
    marginTop: 15,
    color: primaryColor[0],
    textAlign: "center",
  },
  resendEmailContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    justifyContent: "center",
    color: primaryColor[0],
    "&:hover": {
      color: primaryColor[0],
    },
    textAlign: "center",
  },
  pointer: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  boxBackground: {
    width: 665,
    height: "42%",
    marginTop: 20,
    marginBottom: 60,
    marginRight: "auto",
    marginLeft: "auto",
    padding: "90px 117px 90px 117px",
    objectFit: "contain",
    borderRadius: "32.5px",
    boxShadow: "0 2.5px 25px 0 rgba(0, 0, 0, 0.5)",
    backgroundColor: "var(--very-light-pink)",
    backgroundImage: `url(${awesomeSmile})`,
    backgroundSize: "279px 279px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      padding: "80px 20px",
      boxShadow: "none",
      width: "100%",
      borderRadius: 0,
      // backgroundImage: "none",
    },
  },
  coursesHeaderImage: {
    width: "100%",
    margin: "auto",
    paddingBottom: "40.5px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  paymentSuccessText: {
    width: "100%",
    height: "8.4%",
    margin: "91px 0 51.5px",
    objectFit: "contain",
    fontFamily: "Montserrat",
    fontSize: "25px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "0.8",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#8c7e4c",
    lineHeight: 1.25,
  },
  paymentSuccessImg: {
    width: "78.5px",
    height: "59.7px",
    margin: "auto",
    display: "block",
    objectFit: "contain",
  },
});

export default emailConfirmationStyle;
