import { primaryColor } from "assets/jss/material-kit-pro-react";
const aboutKaisStyle = (theme) => ({
  root: {
    margin: "30px 0px",
    padding: "0px 70px",
    paddingBottom: 20,
    borderBottom: "1px solid #aaa",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 0px",
      paddingBottom: 20,
    },
  },
  pageTitle: {
    textAlign: "center",
    opacity: "0.75",
    fontWeight: "bold",
    color: "#2f2f2f",
    marginBottom: "40px",
    fontSize: "28px",
  },
  title: {
    fontSize: "24px",
    color: "#2f2f2f",
    margin: "30px 0px",
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#2f2f2f",
    marginBottom: "20px",
  },
  text: {
    fontSize: "16px",
    marginBottom: "20px",
  },
  center: {
    textAlign: "center",
  },
  name: {
    color: primaryColor[0],
    fontWeight: "bold",
  },
  image: {
    borderRadius: "5px",
    position: "absolute",
    bottom: -20,
    right: -110,
    flexShrink: "0",
    height: "650px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  socialIcons: {
    margin: "10px",
  },
  icon: {
    margin: "0px 5px",
    height: 14,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 8px",
    },
  },
});

export default aboutKaisStyle;
