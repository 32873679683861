import { blackColor } from "assets/jss/material-kit-pro-react";
import { mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.js";

const allCoursesListStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  coursesContainer: {
    "& .slick-prev::before": {
      color: blackColor,
    },
    "& .slick-next::before": {
      color: blackColor,
    },
    "& .slick-prev": {
      left: -100,
    },
    "& .slick-next": {
      right: -100,
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  paginationContainer: {
    margin: "auto",
    marginTop: 20,
    width: "fit-content",
  },
});

export default allCoursesListStyle;
