import { makeStyles } from "@material-ui/core/styles";
import { whiteColor } from "assets/jss/material-kit-pro-react.js";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import api from "services/api.js";
import styles from "./footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const { children, theme, big, className } = props;
  const classes = useStyles();
  const themeType =
    theme === "transparent" || theme === undefined ? false : true;
  const footerClasses = classNames({
    [classes.footerClass]: true,
    [classes[theme]]: themeType,
    [classes.big]: big || children !== undefined,
    [className]: className !== undefined,
  });
  const [featuredCourses, setFeaturedCourses] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      try {
        const featuredCoursesResponse = (await api.getFeaturedCourses()).data
          .data;
        setFeaturedCourses(featuredCoursesResponse);
      } catch (err) {
        // setLoading(false);
      }
    })();
  }, []);
  let courseLinks = [];
  if (featuredCourses.length > 0) {
    featuredCourses.forEach((course, key) => {
      if (key < 3)
        courseLinks.push(
          <li key={key}>
            <Link to={`/course?courseId=${course?.id}`}>{course.name}</Link>
          </li>
        );
    });
  }

  const openFacebook = () => {
    const win = window.open("https://www.facebook.com/greymatterufb", "_blank");
  };

  const openInstagram = () => {
    const win = window.open("https://www.instagram.com/greymatteru", "_blank");
  };

  const openTwitter = () => {
    const win = window.open("https://twitter.com/greymatterU1", "_blank");
  };

  const openYoutube = () => {
    const win = window.open(
      "https://www.youtube.com/channel/UCNZJE7IBjJ7I9wegRI6vZUg",
      "_blank"
    );
  };

  const openIncrypt = () => {
    window.open("https://www.incryptsolutions.com", "_blank");
  };

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div>
          <div className={classes.content}>
            <div className={classes.footer}>
              <GridContainer
                justify="center"
                className={classes.gridContainerMargin}
              >
                <GridItem xs={12} sm={12} md={3} style={{ margin: "0px auto" }}>
                  <Link to="/home">
                    <h5>GREY MATTER U</h5>
                  </Link>
                  <p style={{ color: "white", fontWeight: 100 }}>
                    Learn From The World’s Best Teachers Who Lead By Example.
                    Truly Successful People Want To Help Others To Excel And
                    Achieve Success Quickly.
                  </p>
                </GridItem>
                <GridItem xs={6} sm={6} md={2} style={{ margin: "0px auto" }}>
                  <h5>Main Links</h5>
                  <ul className={classes.linksVertical}>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/courses">Courses</Link>
                    </li>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </GridItem>
                {featuredCourses.length > 0 ? (
                  <GridItem xs={6} sm={6} md={2} style={{ margin: "0px auto" }}>
                    <h5>Our Courses</h5>
                    <ul className={classes.linksVertical}>{courseLinks}</ul>
                  </GridItem>
                ) : null}
              </GridContainer>
            </div>
          </div>
        </div>
        <div>
          <ul className={classes.socialButtons}>
            <li>
              <Button
                justIcon
                simple
                onClick={openFacebook}
                color="white"
                className={classes.socialIconMargin}
              >
                <i className={"fab fa-facebook " + classes.logoSize} />
              </Button>
            </li>
            <li>
              <Button
                justIcon
                simple
                onClick={openInstagram}
                color="white"
                className={classes.socialIconMargin}
              >
                <i className={"fab fa-instagram " + classes.logoSize} />
              </Button>
            </li>
            <li>
              <Button
                justIcon
                simple
                onClick={openTwitter}
                color="white"
                className={classes.socialIconMargin}
              >
                <i className={"fab fa-twitter " + classes.logoSize} />
              </Button>
            </li>
            <li>
              <Button
                justIcon
                simple
                onClick={openYoutube}
                color="white"
                className={classes.socialIconMargin}
              >
                <i className={"fab fa-youtube " + classes.logoSize} />
              </Button>
            </li>
            {/* <li>
              <Button
                justIcon
                simple
                onClick={openLinkedin}
                color="white"
                className={classes.socialIconMargin}
              >
                <i className={"fab fa-linkedin " + classes.logoSize} />
              </Button>
            </li> */}
          </ul>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div className={classNames(classes.pullCenter, classes.copyRight)}>
              Copyright &copy; {(1900 + new Date().getYear()).toString()}{" "}
              <Link to="/" style={{ color: whiteColor }}>
                Grey Matter U.
              </Link>{" "}
              All Rights Reserved.
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ fontWeight: 100 }}>Powered by </div>
              <Button
                className={classes.incrypt}
                onClick={openIncrypt}
                color="transparent"
              >
                INCRYPT Solutions
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.clearFix} />
      </div>
    </footer>
  );
}

Footer.propTypes = {
  theme: PropTypes.oneOf(["dark", "white", "transparent"]),
  big: PropTypes.bool,
};
