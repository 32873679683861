import { Carousel } from "3d-react-carousal";
import SlickCarousel from "react-slick";
import { makeStyles } from "@material-ui/core/styles";
import checkIcon from "assets/images/checkIcon.png";
import tribal from "assets/images/tribal.png";
import CourseCard from "components/CourseCard/CourseCard.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import api from "services/api.js";
import style from "./infoStyle.js";
import CourseCardSkeleton from "components/CourseCard/CourseCardSkeleton.js";
import { useHistory } from "react-router";

const useStyles = makeStyles(style);

export default function Info() {
  const classes = useStyles();
  const history = useHistory();
  const [featuredCourses, setFeaturedCourses] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const featuredCoursesResponse = (await api.getFeaturedCourses()).data
          .data;
        setFeaturedCourses(featuredCoursesResponse);
        setLoading(false);
      } catch (err) {
        // setLoading(false);
      }
    })();
  }, []);
  const slides = [];
  if (featuredCourses) {
    featuredCourses.forEach((course, key) => {
      slides.push(<CourseCard key={key} long mAuto course={course} />);
    });
  }
  return (
    <div>
      <GridContainer justify="center" className={classes.infoSection}>
        <GridItem xs={12} sm={12} md={6} className={classes.infoSingle}>
          <GridContainer justify="center">
            <div className={classes.infoHeader}>
              <h3 style={{ fontWeight: "bold", margin: 0 }}>Horizons</h3>
              <span className={classes.headerUnderline} />
            </div>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} className={classes.infoBody}>
              <img
                className={classes.infoImage}
                style={{ minWidth: 96 }}
                height={96}
                width={96}
                src={checkIcon}
              />
              <p style={{ textAlign: "center", marginTop: 30 }}>
                We always make sure to get you global best practices, stories
                and connections to help you expand your horizons.
              </p>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} className={classes.infoSingle}>
          <GridContainer justify="center">
            <div className={classes.infoHeader}>
              <h3 style={{ fontWeight: "bold", margin: 0 }}>Tribal</h3>
              <span className={classes.headerUnderline} />
            </div>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} className={classes.infoBody}>
              <img
                className={classes.infoImage}
                style={{ minWidth: 168 }}
                height={96}
                width={168}
                src={tribal}
              />
              <p style={{ textAlign: "center", marginTop: 30 }}>
                When you join GMU you are a part of a tribe that aims at
                supporting each other and changing the world for the better.
              </p>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center" className={classes.rowContainer}>
        <GridItem
          xs={12}
          sm={12}
          md={5}
          className={classes.findCoursesContainer}
        >
          <h2 style={{ textAlign: "center" }}>
            Find the Courses that Fascinate You
          </h2>
          <Button
            round
            color="primary"
            className={classes.browse + " " + classes.button}
            onClick={() => {
              history.push({
                pathname: "courses",
              });
            }}
          >
            Browse all Courses
          </Button>
          <p style={{ textAlign: "center" }}>
            Bespoke programs designed as per your unique needs
          </p>
        </GridItem>
        <GridItem xs={12} sm={12} md={7} className={classes.carouselContainer}>
          {loading ? (
            <CourseCardSkeleton long mAuto />
          ) : slides.length > 2 ? (
            <Carousel slides={slides} autoplay={false} interval={4000} />
          ) : (
            <SlickCarousel
              {...{
                dots: false,
                infinite: true,
                speed: 600,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: false,
                autoplaySpeed: 4000,
                arrows: true,
              }}
            >
              {slides}
            </SlickCarousel>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
}
