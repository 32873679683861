import kaisVideoThumbnail from "assets/images/kaisVideoThumbnail.png";
import { whiteColor } from "assets/jss/material-kit-pro-react";
import {
  container,
  mlAuto,
  mrAuto,
} from "assets/jss/material-kit-pro-react.js";

const aboutStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  root: {
    padding: "0px 0px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "60px 0px",
      paddingTop: 120,
    },
  },
  container: {
    ...container,
    zIndex: 1,
  },
  headerImageContainer: {
    minHeight: 168,
    [theme.breakpoints.down("sm")]: {
      minHeight: 0,
    },
  },
  shadowContainer: {
    boxShadow: "0 2.5px 25px 0 rgba(0, 0, 0, 0.5)",
    width: "90%",
    borderRadius: 32.5,
    backgroundSize: "contain",
    backgroundPosition: "top center",
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    height: "100%",
    padding: "80px 15px",
    paddingBottom: 0,
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px",
      boxShadow: "none",
      width: "100%",
      borderRadius: 0,
      backgroundImage: "none",
    },
  },
  aboutHeader: {
    width: "100%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      // display: "none",
    },
  },
  aboutContainer: {
    marginTop: 60,
    marginBottom: 60,
  },
  videoContainer: {
    boxShadow: "0 2.5px 25px 0 rgba(0, 0, 0, 0.5)",
    backgroundImage: `linear-gradient(
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)
    ),url(${kaisVideoThumbnail})`,
    backgroundSize: "cover",
    backgroundPosition: "top center",
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    height: "40vw",
    maxHeight: "600px",
    borderRadius: 32.5,
    margin: "60px auto",
    marginTop: 0,
    padding: "50px 40px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
      height: "50vw",
      borderRadius: 12.5,
    },
  },
  videoHeader: {
    color: whiteColor,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  videoDescription: {
    color: "#fff9",
    fontWeight: 300,
    margin: "40px 0px",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  playButton: {
    position: "absolute",
    cursor: "pointer",
    width: 80,
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("xs")]: {
      width: "20%",
    },
  },
  videoKaisPosition: {
    color: "#fffd",
    fontWeight: 300,
    marginTop: "auto",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  ourClientsContainer: {
    borderBottom: "1px solid #aaa",
  },
});

export default aboutStyle;
