import { makeStyles } from "@material-ui/core/styles";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { Rating } from "@material-ui/lab";
import coursesImage from "assets/images/courses.png";
import defaultCourse from "assets/images/default-course.png";
import AboutKais from "components/AboutKais/AboutKais.js";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import OurClients from "components/OurClients/OurClients.js";
import Table from "components/Table/Table";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import api from "services/api.js";
import { formatAMPM, getDateString } from "utils/index.js";
import CourseDetailsSkeleton from "./CourseDetailsSkeleton.js";
import courseDetailsStyle from "./courseDetailsStyle.js";
import PaymentModal from "components/CustomModals/PaymentModal/PaymentModal";
import ErrorModal from "components/CustomModals/ErrorModal/ErrorModal";
import { toggleShowAuthModal, setAuthRedirectPath } from "store/actions";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles(courseDetailsStyle);

export default function CourseDetails() {
  const { user, showAuthModal } = useSelector((state) => state.auth);
  const loggedIn = user !== null;

  const classes = useStyles();
  let location = useLocation();
  const dispatch = useDispatch();
  const [course, setCourse] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [paymentModalOpen, setPaymentModalOpen] = React.useState(false);
  const [errorModalOpen, setErrorModalOpen] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState(false);
  const [errorModalMessage, setErrorModalMessage] = React.useState(null);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const courseId = new URLSearchParams(location.search).get("courseId");
    if (courseId)
      (async () => {
        try {
          setLoading(true);
          const response = (await api.getCourseDetails(courseId)).data;
          setCourse(response);
          setLoading(false);
        } catch (err) {
          // setLoading(false);
        }
      })();
  }, []);

  let modulesData = [];

  if (course) {
    course.groups.forEach((group) => {
      let groupData = {
        when: group.title,
        button: course.enrolled ? null : (
          <Button
            className={classes.button}
            color={course.status ? "primary" : "disabled"}
            disabled={course.status ? false : true}
            onClick={() => {
              if (course.status) {
                if (loggedIn) {
                  setSelectedGroup(group);
                  setPaymentModalOpen(true);
                } else {
                  dispatch(toggleShowAuthModal());
                }
              }
            }}
          >
            Book your slot
          </Button>
        ),
        data: [],
      };
      if (group.groupSessions.length > 0) {
        group.groupSessions.forEach((session) => {
          let sessionFromDate = new Date(session.date);
          let sessionToDate = new Date(
            sessionFromDate.getTime() + session.lecture?.duration * 60000
          );
          groupData.data.push([
            <div className={classes.text}>{session.lecture.title}</div>,
            <div className={classes.date}>
              {getDateString(sessionFromDate)}
              <br />
              {formatAMPM(
                `${sessionFromDate.getHours()}:${sessionFromDate.getMinutes()}`
              )}{" "}
              -{" "}
              {formatAMPM(
                `${sessionToDate.getHours()}:${sessionToDate.getMinutes()}`
              )}
            </div>,
          ]);
        });
        modulesData.push(groupData);
      }
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  let totalMinutes = course?.totalDuration ? course.totalDuration : 0;
  let hours = Math.floor(totalMinutes / 60);
  let minutes = totalMinutes % 60;
  let duration = (hours > 0 ? hours + "h " : "") + minutes + "m";

  return (
    <div className={classes.root}>
      <ErrorModal
        open={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        message={errorModalMessage}
      />
      <PaymentModal
        open={paymentModalOpen}
        onClose={() => {
          setPaymentModalOpen(false);
        }}
        course={course}
        onError={(message) => {
          setErrorModalMessage(message);
          setErrorModalOpen(true);
        }}
        selectedGroup={selectedGroup}
      />
      <div className={classes.container + " " + classes.headerImageContainer}>
        <img
          className={classes.coursesHeaderImage}
          src={coursesImage}
          alt="coursesImage"
        />
      </div>
      <div className={classes.container + " " + classes.shadowContainer}>
        <h1 className={classes.pageTitle}>COURSE</h1>
        {loading ? (
          <CourseDetailsSkeleton />
        ) : (
          <div>
            <div className={classes.courseDataContainer}>
              <GridContainer>
                <GridItem sm={8} md={8}>
                  <h2 className={classes.title}>{course?.name}</h2>
                  <h3 className={classes.subtitle}>Description</h3>
                  <div className={classes.text}>{course?.description}</div>
                  {/* <div>
                    <Rating
                      name="courseRating"
                      precision={0.1}
                      value={4}
                      readOnly
                      icon={
                        <StarIcon
                          className={classes.ratingStar}
                          fontSize="inherit"
                        />
                      }
                      emptyIcon={
                        <StarBorderIcon
                          className={classes.ratingStar}
                          fontSize="inherit"
                        />
                      }
                    ></Rating>
                  </div> */}
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div
                        className={classes.subtitle}
                        style={{ marginTop: 8 }}
                      >
                        About the instructor <br />
                        <br />
                        <div className={classes.instructorName}>
                          {course?.instructor?.name}
                        </div>
                      </div>
                      <div className={classes.text}>
                        {course?.instructor?.description}
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      {course?.enrolled ? (
                        <Button
                          onClick={() => {
                            window.open(course?.zoomLink, "_blank");
                          }}
                          className={classes.button}
                          color="primary"
                        >
                          Go to Zoom Meeting
                        </Button>
                      ) : null}
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem sm={4} md={4}>
                  <div className={classes.imageConatainer}>
                    {course?.imgUrl ? (
                      <img
                        className={classes.courseImage}
                        src={course?.imgUrl}
                        alt="coursesImage"
                      />
                    ) : (
                      <img
                        className={classes.courseImage}
                        src={defaultCourse}
                        alt="coursesImage"
                      />
                    )}
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem sm={12} md={8}>
                  <div className={classes.title}>Course Dates</div>
                  <Table
                    tableHead={["When", "Module", "Date & Time"]}
                    tableData={modulesData}
                    tableHeaderColor="primary"
                    noDataMessage={""}
                    courseTable
                    // mobileResponsive={false}
                  />
                </GridItem>
                <GridItem sm={12} md={4}>
                  <div className={classes.courseDetailsCard}>
                    <div className={`${classes.title} ${classes.center}`}>
                      Course Details
                    </div>
                    <div className={classes.courseDetailsCardText}>
                      <span className={classes.bold}>Course: </span>
                      <span>{course?.name}</span>
                    </div>
                    <div className={classes.courseDetailsCardText}>
                      <span className={classes.bold}>Duration: </span>
                      <span>
                        {course?.durationString
                          ? course?.durationString
                          : duration}
                      </span>
                    </div>
                    <div
                      className={` ${classes.courseDetailsCardText} ${classes.center}`}
                    >
                      <img
                        className={`${classes.zoomImage}`}
                        src="img/zoom.png"
                      />
                    </div>
                    <div className={classes.courseDetailsCardText}>
                      <span className={classes.bold}>Pricing: </span>
                      <span>{course?.price}$</span>
                    </div>
                    <div className={classes.courseDetailsCardText}>
                      <span className={classes.bold}>Payment Options: </span>
                    </div>
                    <div className={classes.center}>
                      <img
                        className={classes.paymentImage}
                        src="img/payment.png"
                      ></img>
                    </div>
                    <div className={classes.courseDetailsCardText}>
                      <span className={classes.bold}>Status: </span>
                      {course?.status ? (
                        <span>Bookings now open</span>
                      ) : (
                        <span>Bookings are closed</span>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className={classes.center}>
                      <img
                        className={classes.moneyBackImage}
                        src="img/money_back.png"
                      />
                    </div>
                    <div className={classes.center}>
                      <h2 className={classes.title}>Money-Back Guarantee</h2>
                      <p className={classes.text}>
                        If you're not completely satisfied, simply cancel within
                        your first module for a full refund.
                      </p>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
            <GridContainer className={classes.whatYouWilLearnContainer}>
              <GridItem sm={12} md={12}>
                <h2 className={classes.title}>What you will learn</h2>
                <p className={classes.text}>{course?.whatYouWillLearn}</p>
              </GridItem>
              <GridItem sm={12} md={12}>
                <h2 className={classes.title}>Who this course is for</h2>
                <p className={classes.text}>{course?.whoThisCourseIsFor}</p>
              </GridItem>
            </GridContainer>{" "}
          </div>
        )}
        {/* <AboutKais /> */}
        <OurClients
          testimonialsOnly
          courseId={course ? course.id : undefined}
        />
      </div>
    </div>
  );
}
