import { makeStyles } from "@material-ui/core/styles";
import christian from "assets/images/christian.jpg";
import classNames from "classnames";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import React from "react";
import style from "./teamCardStyle.js";
import defaultPerson from "assets/images/default-person.png";

const useStyles = makeStyles(style);

export default function TeamCard(props) {
  const classes = useStyles();
  const { member, big } = props;
  const avatarClasses = classNames({
    [classes.avatar]: !big,
    [classes.centerAvatar]: big,
  });
  return (
    <div>
      <Card testimonial plain className={classes.teamCard}>
        <CardAvatar testimonial plain className={avatarClasses}>
          {member?.imgUrl === null ? (
            <img src={defaultPerson} alt={member?.name} />
          ) : (
            <img src={member?.imgUrl} alt={member?.name} />
          )}
        </CardAvatar>
        <CardBody plain>
          <h4 className={classes.cardTitle}>{member?.name}</h4>
          <h6 className={classes.job}>{member?.title}</h6>
          <p className={classes.cardDescription}>{member?.description}</p>
        </CardBody>
      </Card>
    </div>
  );
}
