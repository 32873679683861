import { mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.js";

const emailVerificationStyle = (theme) => ({
  mrAuto,
  mlAuto,

  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },

  appLogo: {
    width: 200,
    margin: "auto",
  },
  checkCircleOutlinedIcon: {
    width: 100,
    height: 100,
    color: "#05B41C",
  },

  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "cetner",
    alignItems: "center",
    padding: 25,
  },
  alignCenter: {
    textAlign: "center",
  },
});

export default emailVerificationStyle;
