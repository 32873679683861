import * as actionTypes from "./actionTypes";
import enums from "enums";
import api from "services/api";

const authSuccess = (user) => ({
  type: actionTypes.AUTH_SUCCESS,
  payload: { user },
});

export const authLogout = () => {
  localStorage.clear();
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

const checkAuthTimeout = (expirationTime) => (dispatch) => {
  setTimeout(() => {
    dispatch(authLogout());
  }, expirationTime * 1000);
};

export const tryAuth = (authData, authMode) => async (dispatch) => {
  let req;
  if (authMode === enums.AuthMode.SIGNUP) {
    req = api.signUpStudent(authData);
  } else {
    req = api.signInStudent(authData);
  }
  const res = await req;
  const { accessToken, refreshToken, ...user } = res.data;
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("refreshToken", refreshToken);
  api.setAuthToken(accessToken);
  dispatch(authSuccess(user));
};

export const uploadStudentImage = (image) => async (dispatch) => {
  const res = await api.uploadStudentImage(image);
  localStorage.setItem("user", JSON.stringify(res.data));
  dispatch(authSuccess(res.data));
};

export const updateStudentInfo = (info) => async (dispatch) => {
  const res = await api.updateStudentInfo(info);
  localStorage.setItem("user", JSON.stringify(res.data));
  dispatch(authSuccess(res.data));
};

export const authCheckState = () => async (dispatch) => {
  const res = await api.getCurrentUser();

  if (res.data === null) dispatch(authLogout());
  else {
    /*
    localStorage returns items as strings, so need to convert expiration date
    to date type in order to make valid comparisons
    */

    const { accessToken, refreshToken, ...user } = res.data;
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    api.setAuthToken(accessToken);
    dispatch(authSuccess(user));

    // checkAuthTokenExpiryAPI
    // const expirationDate = new Date(localStorage.getItem('expirationDate'));
    // if (expirationDate > new Date()) {
    //   axios
    //     .post(`${BASE_URL}/getAccountInfo?key=${API_KEY}`, { idToken: token })
    //     .then(res => {
    //       const localId = res.data.users[0].localId;
    //       dispatch(authSuccess(token, localId));
    //       dispatch(checkAuthTimeout((expirationDate - new Date()) / 1000));
    //     })
    //     .catch(err => console.log(err));
    // } else dispatch(authLogout());
  }
};

export const toggleShowAuthModal = () => ({
  type: actionTypes.TOGGLE_SHOW_AUTH_MODAL,
});
