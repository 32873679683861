import { makeStyles } from "@material-ui/core/styles";
import myPreviousImg from "assets/images/myprevious.png";
import Table from "components/Table/Table";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { formatAMPM, getDateString } from "utils/index.js";
import React, { useEffect, useState } from "react";
import api from "services/api";
import style from "./previousCoursesStyle.js";

const useStyles = makeStyles(style);

export default function PreviousCourses(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    prevCourses: null,
  });

  const stateHandler = (key, value) => {
    setState({ ...state, [key]: value });
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    (async () => {
      try {
        const prevCourses = (await api.getOrdersofCurrentUser(1)).data;
        stateHandler("prevCourses", prevCourses);
      } catch (err) {
        // setLoading(false);
      }
    })();
  }, []);

  let previousCoursesData = [];

  if (state.prevCourses) {
    state.prevCourses.data.forEach((order, index) => {
      order.orderItems.forEach((orderItem, itemIndex) => {
        let orderDate = new Date(orderItem.group.course.createdAt);
        previousCoursesData.push([
          <span className={classes.cell} key={itemIndex}>
            {orderItem.id}
          </span>,
          <span className={classes.cell} key={itemIndex}>
            {orderItem.group.course.name}
          </span>,
          <span className={classes.cell} key={itemIndex}>
            {orderItem.group.title}
          </span>,
          <span className={classes.cell} key={itemIndex}>
            {(orderItem.price - (order.promoDiscount || 0)).toFixed(2)}
          </span>,
          <span className={classes.cell} key={itemIndex}>
            {getDateString(orderDate)}{" "}
            {formatAMPM(`${orderDate.getHours()}:${orderDate.getMinutes()}`)}
          </span>,
        ]);
      });
    });
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img
          className={classes.coursesHeaderImage}
          src={myPreviousImg}
          alt="myPreviousImg"
        />
      </div>
      <div className={classes.container + " " + classes.shadowContainer}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <h1 className={classes.pageTitle}>PREVIOUS ORDERS</h1>
            <Table
              tableHead={["ORDER #", "COURSE", "GROUP", "PRICE", "TIME"]}
              tableData={previousCoursesData}
              noDataMessage="You haven't purchased any courses yet."
              pagination
              mobileResponsive
              // round
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
