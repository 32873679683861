import {
  container,
  mlAuto,
  mrAuto,
  textColor,
  primaryColor,
} from "assets/jss/material-kit-pro-react.js";

const myCoursesStyle = (theme) => ({
  mrAuto,
  mlAuto,

  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  root: {
    padding: "0px 0px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "60px 0px",
    },
  },
  container: {
    ...container,
    zIndex: 1,
  },
  headerImageContainer: {
    minHeight: 138,
    [theme.breakpoints.down("sm")]: {
      minHeight: 0,
    },
  },
  shadowContainer: {
    boxShadow: "0 2.5px 25px 0 rgba(0, 0, 0, 0.5)",
    width: "90%",
    borderRadius: 32.5,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
      width: "100%",
      borderRadius: 0,
    },
  },
  contactHeader: {
    width: "100%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contactContainer: {
    marginTop: 60,
    marginBottom: 60,
  },
  section: {
    padding: "40px 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 0px",
    },
  },
  heading: {
    textAlign: "center",
    color: textColor[0],
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
      fontSize: "1.5rem",
    },
  },
  coursesContainer: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  noCoursesMessage: {
    textAlign: "center",
    color: textColor[0],
    fontSize: 20,
    margin: "40px auto",
  },
  gotoCoursesLink: {
    color: primaryColor[0],
    "&:hover": {
      color: primaryColor[0],
    },
  },
});

export default myCoursesStyle;
