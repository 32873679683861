import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button";
import AuthModal from "components/CustomModals/AuthModal/AuthModal";
import strings from "constants/strings";
import enums from "enums";
import defaultPerson from "assets/images/default-person.png";
import Popover from "@material-ui/core/Popover";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import styles from "./headerLinksStyle.js";
import Hidden from "@material-ui/core/Hidden";
import checkCircle from "assets/images/check-circle.png";
import logOutIcon from "assets/images/log-out.png";
import historyIcon from "assets/images/history.png";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { authLogout, toggleShowAuthModal } from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(styles);
export default function HeaderLinks(props) {
  const { dropdownHoverColor, closeDrawer } = props;

  const location = useLocation();

  const dispatch = useDispatch();

  const { user, showAuthModal } = useSelector((state) => state.auth);

  const loggedIn = user !== null;

  const classes = useStyles();
  const history = useHistory();
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };
  const [anchorElBottom, setAnchorElBottom] = React.useState(null);
  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  var onClickSections = {};

  // const [showAuthModal, setShowAuthModal] = React.useState(false);

  const [authMode, setAuthMode] = React.useState(enums.AuthMode.SIGNUP); // enums.AuthMode.SIGNUP

  const handleAuthModeChange = () =>
    setAuthMode((prevAuthMode) =>
      prevAuthMode === enums.AuthMode.SIGNUP
        ? enums.AuthMode.LOGIN
        : enums.AuthMode.SIGNUP
    );

  const handleShowAuthModal = (authMode) => () => {
    setAuthMode(authMode);
    // setShowAuthModal(true);
    dispatch(toggleShowAuthModal());
  };

  const closeDrawerAndPopover = () => {
    closeDrawer();
    setAnchorElBottom(null);
  };

  const handleLogout = () => {
    dispatch(authLogout());
    closeDrawerAndPopover();
  };

  const getInitials = (name) => {
    var names = name.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const profileLinks = (
    <div style={{ width: "100%" }}>
      <Link
        to={"/profile"}
        onClick={closeDrawerAndPopover}
        className={classes.userContainer}
      >
        {user?.imgUrl ? (
          <img src={user?.imgUrl} alt={user?.name} className={classes.img} />
        ) : (
          <img src={defaultPerson} alt={user?.imgUrl} className={classes.img} />
        )}
        <div>
          <div className={classes.popOverHeading}>{user?.name}</div>
          <div className={classes.text}>Go to profile</div>
        </div>
      </Link>
      <Link
        to={"/mycourses"}
        onClick={closeDrawerAndPopover}
        className={classes.userLinkContainer}
      >
        <img src={checkCircle} alt="..." className={classes.icon} />
        <div className={classes.popOverHeading}>My Courses</div>
      </Link>
      <Link
        to={"/previouscourses"}
        onClick={closeDrawerAndPopover}
        className={classes.userLinkContainer + " " + classes.borderBottomMobile}
      >
        <img src={historyIcon} alt="..." className={classes.icon} />
        <div className={classes.popOverHeading}>Previous Orders</div>
      </Link>
    </div>
  );

  return (
    <List className={classes.list + " " + classes.mAuto}>
      <div style={{ margin: "auto" }}>
        {loggedIn ? (
          <Hidden mdUp implementation="js">
            <ListItem className={classes.profileLinksMobileContainer}>
              {profileLinks}
            </ListItem>
          </Hidden>
        ) : null}
        <ListItem className={classes.listItem}>
          <Link
            onClick={closeDrawerAndPopover}
            to="/"
            className={
              classes.headerLink +
              (location.pathname === "/" ? " " + "whiteLink" : "")
            }
          >
            HOME
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link
            onClick={closeDrawerAndPopover}
            to="/about"
            className={
              classes.headerLink +
              (location.pathname === "/" ? " " + "whiteLink" : "")
            }
          >
            ABOUT US
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link
            onClick={closeDrawerAndPopover}
            to="/courses"
            className={
              classes.headerLink +
              (location.pathname === "/" ? " " + "whiteLink" : "")
            }
          >
            COURSES
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link
            onClick={closeDrawerAndPopover}
            to="/contact"
            className={
              classes.headerLink +
              (location.pathname === "/" ? " " + "whiteLink" : "")
            }
          >
            CONTACT US
          </Link>
        </ListItem>
      </div>

      {loggedIn ? null : (
        <ListItem className={classes.socialIcons}>
          <Button
            className={
              classes.loginButton +
              (location.pathname === "/" ? " " + "whiteButton" : "")
            }
            onClick={handleShowAuthModal(enums.AuthMode.LOGIN)}
          >
            {strings.login}
          </Button>
          <Button
            className={classes.signUpButton}
            onClick={handleShowAuthModal(enums.AuthMode.SIGNUP)}
          >
            {strings.signUp}
          </Button>
        </ListItem>
      )}
      {loggedIn ? (
        <Hidden
          smDown
          implementation="css"
          className={classes.profileButtonHiddenContainer}
        >
          <ListItem className={classes.profileButtonContainer}>
            <div onClick={(event) => setAnchorElBottom(event.currentTarget)}>
              <Button className={classes.profileButton}>
                {getInitials(user?.name)}
                <ArrowDropDownIcon />
              </Button>
            </div>
            <Popover
              classes={{
                paper: classes.popover,
              }}
              open={Boolean(anchorElBottom)}
              anchorEl={anchorElBottom}
              onClose={() => setAnchorElBottom(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {profileLinks}
              <a
                href={`${process.env.REACT_APP_API_HOST}/students/logout`}
                className={classes.userLinkContainer + " " + classes.borderTop}
              >
                <img
                  src={logOutIcon}
                  alt="..."
                  className={classes.logOutIcon}
                />
                <div className={classes.popOverHeading}>Log out</div>
              </a>
            </Popover>
          </ListItem>
        </Hidden>
      ) : null}
      {loggedIn ? (
        <Hidden mdUp implementation="js">
          <ListItem className={classes.listItem}>
            <a
              href={`${process.env.REACT_APP_API_HOST}/students/logout`}
              className={classes.userLinkContainer + " " + classes.borderTop}
            >
              <img src={logOutIcon} alt="..." className={classes.logOutIcon} />
              <div className={classes.popOverHeading}>Log out</div>
            </a>
          </ListItem>
        </Hidden>
      ) : null}
      {/* {showAuthModal ? ( */}
      <AuthModal
        open={showAuthModal}
        authMode={authMode}
        handleAuthModeChange={handleAuthModeChange}
        onClose={() => dispatch(toggleShowAuthModal())}
        // onClose={() => setShowAuthModal(false)}
      />
      {/* ) : null} */}
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary",
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
};
