import { textColor } from "assets/jss/material-kit-pro-react";
import { primaryColor } from "assets/jss/material-kit-pro-react";
import {
  container,
  mlAuto,
  mrAuto,
  whiteColor,
  blackColor,
} from "assets/jss/material-kit-pro-react.js";

const infoStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  container: {
    ...container,
    zIndex: 1,
  },
  infoSection: {
    padding: "100px 40px",
    paddingTop: 150,
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  infoSingle: {
    padding: "0px 60px",
    marginBottom: 30,
    [theme.breakpoints.down("lg")]: {
      padding: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  headerUnderline: {
    borderRadius: 100,
    backgroundColor: primaryColor[0],
    height: 5,
    width: "60%",
    marginTop: 5,
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
      height: 1,
      marginBottom: 20,
    },
  },
  infoHeader: {
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "center",
    },
  },
  infoBody: {
    display: "flex",
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  infoImage: {
    marginBottom: "auto",
    marginTop: 20,
    marginRight: 10,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  carouselContainer: {
    minHeight: 476,
    "& .proactive": {
      perspective: "1500px !important",
    },
    "& .preactive": {
      perspective: "1500px !important",
    },
    "& .preactive .slider-single-content": {
      transform: "translateX(-42%) scale(0.7) rotateY(59deg) !important",
      opacity: "0.5 !important",
    },
    "& .proactive .slider-single-content": {
      transform: "translateX(42%) scale(0.7) rotateY(-59deg) !important",
      opacity: "0.5 !important",
    },
    "& .slider-single-content": {
      boxShadow: "none !important",
      transition: "0.7s ease-out !important",
    },
    "& .slider-left": {
      opacity: "0 !important",
      marginRight: "-30px !important",
      padding: "20px 42px !important",
    },
    "& .slider-right": {
      opacity: "0 !important",
      marginLeft: "-30px !important",
      padding: "20px 42px !important",
    },
    "& .slider-left div": {
      border: "none !important",
    },
    "& .slider-right div": {
      border: "none !important",
    },
    "& .slick-prev::before": {
      color: blackColor,
    },
    "& .slick-next::before": {
      color: blackColor,
    },
    [theme.breakpoints.down("sm")]: {
      "& .slider-left": {
        opacity: "1 !important",
        marginRight: "50px !important",
        padding: "20px 15px !important",
      },
      "& .slider-right": {
        opacity: "1 !important",
        marginLeft: "50px !important",
        padding: "20px 15px !important",
      },
    },
    [theme.breakpoints.down("xs")]: {
      "& .slider-left": {
        opacity: "1 !important",
        marginRight: "50px !important",
        marginTop: "100px !important",
        padding: "20px 15px !important",
        height: "50% !important",
      },
      "& .fa-arrow-left": {
        color: textColor[0] + " !important",
      },
      "& .fa-arrow-left:before": {
        content: "'\\f053' !important",
      },
      "& .slider-right": {
        opacity: "1 !important",
        marginLeft: "45px !important",
        marginTop: "100px !important",
        padding: "20px 25px !important",
        height: "50% !important",
        border: "none !important",
      },
      "& .fa-arrow-right": {
        color: textColor[0] + " !important",
      },
      "& .fa-arrow-right:before": {
        content: "'\\f054' !important",
      },
    },
  },
  rowContainer: {
    padding: "0px 50px",
    marginTop: "30px",
    marginBottom: "30px",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  button: {
    position: "relative",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "14px 28px",
    lineHeight: "20px",
    textDecoration: "none",
    textTransform: "capitalize",
    display: "inline-flex",
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontSize: "0.75rem",
      "& > span:first-child": {
        justifyContent: "center",
      },
    },
  },
  browse: {
    padding: "14px 28px",
    margin: "30px 0px",
    "& > span:first-child": {
      color: whiteColor,
      fontSize: 24,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
      marginTop: "20px",
    },
  },
  findCoursesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      // order: 2
    },
  },
});

export default infoStyle;
