import {
  createMuiTheme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import classNames from "classnames";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import strings from "constants/strings";
import React from "react";
import { useForm } from "react-hook-form";
import api from "services/api";
import styles from "./forgotPasswordStyle";

const useStyles = makeStyles(styles);

const CssTextField = withStyles(styles(createMuiTheme()).cssTextField)(
  TextField
);

export default function ForgotPassword(props) {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);

  const [
    showPasswordResetMessage,
    setShowPasswordResetMessage,
  ] = React.useState(false);

  const [values, setValues] = React.useState({ email: "" });

  const { register, handleSubmit, errors, setError } = useForm();

  const validationRules = {
    email: {
      required: "This field is required.",
      pattern: {
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Email not valid",
      },
      maxLength: {
        value: 50,
        message: "Email cannot exceed 50 characters.",
      },
    },
  };

  const handleChange = (key) => (event) =>
    setValues({ ...values, [key]: event.target.value });

  const handleForgotPassword = async () => {
    try {
      setLoading(true);
      await api.forgotPassword(values.email);
      setShowPasswordResetMessage(true);
    } catch (err) {
      if (err.response?.data?.param) {
        setError(err.response.data.param, {
          type: "manual",
          message: err.response.data.message,
        });
      } else {
        console.log(err);
        console.log("Something went wrong!");
      }
    } finally {
      setLoading(false);
    }
  };

  const formClasses = classNames({
    [classes.formContainer]: true,
  });

  return (
    <div className={classes.root}>
      {showPasswordResetMessage ? (
        <div className={classes.resetPasswordTitle}>
          <p>{strings.firstTitleForgotSucceed}</p>
          <p className={classes.email}>{values.email}</p>
          <p>{strings.secondTitleForgotSucceed}</p>
        </div>
      ) : (
        <div className={classes.root}>
          <span className={classes.resetPasswordTitle}>
            We'll send password reset instructions to the email address
            associated with your account
          </span>

          <form
            className={classes.form}
            onSubmit={handleSubmit(handleForgotPassword)}
          >
            <GridContainer justify="center" className={classes.rootContainer}>
              <GridItem xs={12} sm={12} md={12} className={formClasses}>
                <div className={classes.enterEmailTitle}>
                  Enter Email Address
                </div>
                <CssTextField
                  id="email"
                  name="email"
                  error={errors.email?.message !== undefined}
                  helperText={errors.email?.message}
                  inputRef={register(validationRules.email)}
                  label="Email"
                  variant="outlined"
                  size="small"
                  className={classes.textFieldMarginBottom}
                  InputProps={{ className: classes.textField }}
                  onChange={handleChange("email")}
                />

                <Button
                  type="submit"
                  className={classes.resetPasswordButton}
                  loading={loading}
                >
                  Reset Password
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </div>
      )}
    </div>
  );
}
