import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import christian from "assets/images/christian.jpg";
import classNames from "classnames";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import React from "react";
import style from "./teamCardStyle.js";

const useStyles = makeStyles(style);

export default function TeamCardSkeleton(props) {
  const classes = useStyles();
  const { center } = props;
  const avatarClasses = classNames({
    [classes.avatarSkeleton]: !center,
    [classes.centerAvatar]: center,
  });
  return (
    <div className={center ? "" : classes.sideCard}>
      <Card testimonial plain className={classes.teamCard}>
        <Skeleton style={{ margin: "auto" }} variant="circle">
          <CardAvatar testimonial plain className={avatarClasses}>
            <img src={christian} alt="..." />
          </CardAvatar>
        </Skeleton>
        <CardBody plain>
          <Skeleton style={{ margin: "auto" }} variant="rect">
            <h4 className={classes.cardTitle}>Ahmed Abulela</h4>
          </Skeleton>
          <Skeleton variant="rect">
            <p className={classes.cardDescription}>
              Quote Or BRIEF ABOUT THEM Ex: Don't Be Scared Of The Truth Because
              We Need To Restart The Human Foundation. And I Love You Like Kanye
              Loves Kanye.
            </p>
          </Skeleton>
        </CardBody>
      </Card>
    </div>
  );
}
