import { makeStyles } from "@material-ui/core/styles";
import ClientLogo from "components/ClientLogo/ClientLogo.js";
import ClientLogoSkeleton from "components/ClientLogo/ClientLogoSkeleton.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TestimonialCard from "components/TestimonialCard/TestimonialCard.js";
import TestimonialSkeleton from "components/TestimonialCard/TestimonialSkeleton.js";
import React from "react";
import Carousel from "react-slick";
import api from "services/api.js";
import style from "./ourClientsStyle.js";

const useStyles = makeStyles(style);

export default function OurClients(props) {
  const { testimonialsOnly, courseId } = props;
  const [loading, setLoading] = React.useState(false);
  const [testimonials, setTestimonials] = React.useState([null]);
  const [ourClients, setOurClients] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const testimonialsResponse = (await api.getTestimonials(courseId)).data
          .data;
        const ourClientsResponse = (await api.getOurClients()).data.data;
        setTestimonials(testimonialsResponse);
        setOurClients(ourClientsResponse);
        setLoading(false);
      } catch (err) {
        // setLoading(false);
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const testimonialsResponse = (await api.getTestimonials(courseId)).data
          .data;
        setTestimonials(testimonialsResponse);
        setLoading(false);
      } catch (err) {
        // setLoading(false);
      }
    })();
  }, [courseId]);

  let clientsElements = [];
  let testimonialsElements = [];

  if (ourClients) {
    ourClients.forEach((client, index) => {
      clientsElements.push(<ClientLogo key={index} client={client} />);
    });
  }

  if (testimonials) {
    testimonials.forEach((testimonial, index) => {
      testimonialsElements.push(
        <TestimonialCard key={index} testimonial={testimonial} />
      );
    });
  }

  const classes = useStyles();
  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} className={classes.bg}>
          <div className={classes.ourClientsSection}>
            {testimonialsOnly ? null : (
              <div>
                <h3 className={classes.ourCliensHeader}>Our Clients</h3>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={10}>
                    {loading ? (
                      <ClientLogoSkeleton />
                    ) : (
                      <Carousel
                        {...{
                          dots: true,
                          infinite: true,
                          speed: 600,
                          slidesToShow: 1,
                          slidesToScroll: 1,
                          autoplay: true,
                          autoplaySpeed: 3000,
                        }}
                      >
                        {clientsElements}
                      </Carousel>
                    )}
                  </GridItem>
                </GridContainer>
              </div>
            )}
            {loading ? (
              <>
                <h3 className={classes.testimonialHeader}>Testimonials</h3>
                <TestimonialSkeleton />
              </>
            ) : (
              testimonials?.length > 0 && (
                <>
                  <h3 className={classes.testimonialHeader}>Testimonials</h3>
                  <Carousel
                    {...{
                      dots: true,
                      infinite: true,
                      speed: 600,
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      autoplay: false,
                      autoplaySpeed: 5000,
                      arrows: true,
                    }}
                  >
                    {testimonialsElements}
                  </Carousel>
                </>
              )
            )}
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
