import { makeStyles } from "@material-ui/core/styles";
import aboutUsImage from "assets/images/about-us.png";
import playButton from "assets/images/play-icon.png";
import classNames from "classnames";
import AboutKais from "components/AboutKais/AboutKais.js";
import ContactUs from "components/ContactUs/ContactUs.js";
import OurClients from "components/OurClients/OurClients.js";
import React from "react";
import style from "./aboutStyle.js";
import OurTeam from "./OurTeam/OurTeam.js";
import WhoWeAre from "./WhoWeAre/WhoWeAre.js";

const useStyles = makeStyles(style);

export default function About() {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const classes = useStyles();
  return (
    <div>
      <div className={classNames(classes.root)}>
        <div className={classes.container + " " + classes.headerImageContainer}>
          <img
            className={classes.aboutHeader}
            src={aboutUsImage}
            alt="aboutHeader"
          />
        </div>
        <div className={classes.aboutContainer}>
          <div className={classes.container + " " + classes.videoContainer}>
            <h2 className={classes.videoHeader}>Grey Matter U</h2>
            <h4 className={classes.videoDescription}>
              Truly successful people who want to lead you to your own success.
            </h4>
            {/* <img
              className={classes.playButton}
              alt="playButton"
              src={playButton}cdc
            /> */}
            <h4 className={classes.videoKaisPosition}>
              kais badran &nbsp; founder
            </h4>
          </div>
          <div className={classes.container + " " + classes.shadowContainer}>
            <WhoWeAre />
            <AboutKais />
            <OurTeam />
            <div className={classes.ourClientsContainer}>
              <OurClients />
            </div>
            <ContactUs />
          </div>
        </div>
      </div>
    </div>
  );
}
