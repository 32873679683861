import {
  blackColor,
  grayColor,
  hexToRgb,
  infoColor,
  primaryColor,
} from "assets/jss/material-kit-pro-react";

const authModalStyle = (theme) => ({
  authModalContainer: {
    margin: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 600,
    },
  },
  authModalInfo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: grayColor[27],
  },
  authModalInfoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 20,
  },
  authModalInfoTitle: {
    width: 195.5,
    height: 44,
    margin: "0 0 20.8px",
    objectFit: "contain",
    fontFamily: "sans-serif",
    fontSize: 19,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.16,
    letterSpacing: "normal",
    textAlign: "left",
    color: "white",

    //////////////////////////
    width: "100%",
  },
  authModalInfoParagraph: {
    width: 189,
    height: 60,
    margin: "18.3px 6.5px 0 0",
    objectFit: "contain",
    fontFamily: "sans-serif",
    fontSize: 12.5,
    fontWeight: "bold",
    lineHeight: 1.2,
    letterSpacing: "normal",
    textAlign: "left",
    color: "white",

    //////////////////////////
    width: "100%",
    maxWidth: 235,
  },
  authModalBorderContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",

    //////////////////////////
    width: "100%",
  },
  authModalBody: {
    backgroundColor: infoColor[8],
  },
  authModalBodyContainer: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    padding: 10,
  },
  orBorderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
    height: 25,
  },
  orParagraph: { color: "black", fontWeight: "bold" },
  orBorder: {
    width: 100,
    height: 0,
    marginRight: 2,
    marginBottom: 7,
    borderBottom: "solid 1px #707070",
  },
  signUpWithEmail: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 14,
    marginTop: 0,
  },
  border: {
    width: 214.4,
    height: 0,
    alignSelf: "center",
    marginTop: 10,
    marginBottom: 10,
    borderBottom: "solid 1px #707070",
  },
  alreadyMember: { textAlign: "center", fontSize: 12 },
  noPadding: {
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      height: "inherit",
    },
  },
  logo: {
    height: 100,
    width: 150,
    height: 150,
    margin: "0 38.5px 0 16px",
    objectFit: "contain",
  },
  authUpperBorder: {
    width: 90,
    height: 17.5,
    margin: "20.8px 100.3px 0 -2px",
  },
  authLowerBorder: {
    width: 45,
    height: 17.5,
    margin: "-10px 89.3px 0 60.8px",
  },
  buttonOutlined: {
    position: "relative",
    float: "right !important",
    width: "100%",
    padding: 0,
    fontWeight: "bold",
    marginTop: 5,
    marginBottom: 5,
    fontSize: "12px",
    textTransform: "capitalize",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    backgroundColor: "transparent",
    height: 30,
    borderRadius: 5,
    display: "inline-flex",
    color: blackColor,
    "& > span:first-child": {
      color: blackColor,
    },
    borderWidth: 0.5,
    borderStyle: "solid",
    borderColor: grayColor[27],
    [theme.breakpoints.down("sm")]: {
      // width: 230,
      width: "100%",
      height: 40,
      fontSize: 14,
      minWidth: 213,

      marginBottom: "5px",
      marginTop: "5px",
      textAlign: "left",
      // fontSize: "0.75rem",
      "& > span:first-child": {
        justifyContent: "center",
      },
    },
    // "& $icons": {
    //   marginRight: "3px",
    // },
    "&:hover": {
      backgroundColor: "rgba(" + hexToRgb(grayColor[2]) + ", 0.8)",
      color: blackColor,
    },
    "&:focus": {
      color: blackColor,
    },
  },
  socials: {
    position: "absolute",
    left: "15px",
    fontSize: "1.1rem",
  },
  faGoogle: {
    background:
      "conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat",
    "-webkit-background-clip": "text",
    backgroundClip: "text",
    color: "transparent",
    "-webkit-text-fill-color": "transparent",
    // background:
    //   "linear-gradient(to bottom left,transparent 49%,#fbbc05 50%) 0 25%/48% 40%,linear-gradient(to top    left,transparent 49%,#fbbc05 50%) 0 75%/48% 40%,linear-gradient(-40deg ,transparent 53%,#ea4335 54%),linear-gradient( 45deg ,transparent 46%,#4285f4 48%),#34a853;",
    // backgroundRepeat: "no-repeat",
    // "-webkit-background-clip": "text",
  },
  faFacebook: {
    color: "#3b5998",
  },
  faTwitter: {
    color: "#00acee",
  },

  infoArea: {
    color: "#696969",
    // maxWidth: "400px",
    // lineHeight: "30px",
    fontSize: 10,
    textAlign: "center",
    // padding: "0px 0px 20px !important",
    // margin: "40px 100px 40px 40px",
    // textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      margin: "40px auto",
    },
  },

  infoAreaLink: {
    "&,& *,&:hover,&:focus": {
      cursor: "pointer",
      color: primaryColor[0],
      textDecoration: "underline",
      fontWeight: "bold",
    },
  },

  link: {
    cursor: "pointer",
    color: primaryColor[0],
    fontWeight: "bold",
    "&:hover,&:focus": {
      textDecoration: "underline",
      color: primaryColor[0],
    },
  },
});

export default authModalStyle;
