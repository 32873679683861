import * as actionTypes from "../actions/actionTypes";

const initialState = {
  user: null,
  showAuthModal: false,
  authRedirectPath: "/",
};

const authSuccess = (state, action) => ({
  ...state,
  ...action.payload,
});

const authLogout = (state) => ({
  ...state,
  user: null,
});

const setAuthRedirectPath = (state, action) => ({
  ...state,
  authRedirectPath: action.path,
});

const toggleShowAuthModal = (state) => ({
  ...state,
  showAuthModal: !state.showAuthModal,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case actionTypes.TOGGLE_SHOW_AUTH_MODAL:
      return toggleShowAuthModal(state);
    default:
      return state;
  }
};
