import teamBg from "assets/images/team-bg.png";
import {
  blackColor,
  primaryColor,
  textColor,
} from "assets/jss/material-kit-pro-react";
import { mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.js";

const ourTeamStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  ourTeamSection: {
    marginLeft: -15,
    marginRight: -15,
    padding: "0px 15px",
    backgroundImage: `url(${teamBg})`,
    backgroundSize: "cover",
    backgroundPosition: "top center",
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    marginBottom: 80,
    borderBottom: "1px solid #aaa",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none",
    },
    "& .slick-dots li.slick-active button:before": {
      backgroundColor: primaryColor[0],
      boxShadow:
        "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)",
      width: 12,
      height: 12,
      top: 0,
    },
    "& .slick-prev::before": {
      color: blackColor,
    },
    "& .slick-next::before": {
      color: blackColor,
    },
  },
  teamCard: {
    maxWidth: "650px",
    margin: "60px auto",
  },
  teamCard: {
    maxWidth: "650px",
    margin: "60px auto",
    [theme.breakpoints.down("sm")]: {
      margin: "0px auto",
      marginTop: 40,
    },
  },
  cardDescription: {
    marginTop: 20,
  },
  cardTitle: {
    color: primaryColor[0],
  },
  job: {
    color: primaryColor[0],
  },
  ourTeamHeader: {
    textAlign: "center",
    color: textColor[0],
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
      fontSize: "1.5rem",
    },
  },
  testimonialHeader: {
    textAlign: "center",
    color: textColor[0],
    textTransform: "uppercase",
    marginTop: 80,
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
      fontSize: "1.5rem",
    },
  },
  avatar: {
    maxHeight: 170,
    maxWidth: 170,
    marginTop: "40px !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px !important",
      maxHeight: 150,
      maxWidth: 150,
    },
  },
  centerAvatar: {
    maxHeight: 200,
    maxWidth: 200,
    [theme.breakpoints.down("sm")]: {
      maxHeight: 150,
      maxWidth: 150,
    },
  },
  teamContainer: {
    "& .slick-prev::before": {
      color: blackColor,
    },
    "& .slick-next::before": {
      color: blackColor,
    },
    "& .slick-prev": {
      left: -80,
    },
    "& .slick-next": {
      right: -80,
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      "& .slick-prev": {
        left: -10,
      },
      "& .slick-next": {
        right: -10,
      },
      "& .slick-dots": {
        bottom: -15,
      },
    },
  },
});

export default ourTeamStyle;
