/*eslint-disable*/
// @material-ui/core components
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  createMuiTheme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import resetPasswordSuccessImg from "assets/images/material-done.png";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import api from "services/api";
import styles from "./resetPasswordStyle";
const CssTextField = withStyles(styles(createMuiTheme()).cssTextField)(
  TextField
);

const useStyles = makeStyles(styles);

export default function ResetPassword({ ...rest }) {
  const [token, setToken] = React.useState(null);

  const [loading, setLoading] = React.useState(false);

  const [pageLoading, setPageLoading] = React.useState(true);

  const [error, setError] = React.useState(null);

  const history = useHistory();

  const location = useLocation();

  const [showPassword, setShowPassword] = React.useState(false);

  const [resetPasswordSuccess, setResetPasswordSuccess] = React.useState(false);

  const { register, handleSubmit, errors, getValues } = useForm();

  useEffect(() => {
    (async () => {
      const token = new URLSearchParams(location.search).get("token");
      if (!token) history.push("/");
      try {
        await api.checkResetPassword(token);
        setToken(token);
      } catch (err) {
        setError(
          err.response?.data?.errors?.length
            ? err.response.data.errors[0].msg
            : err.response?.data
            ? err.response.data.message
            : "Something went wrong. Please try again later."
        );
      } finally {
        setPageLoading(false);
      }
    })();
  }, []);

  const classes = useStyles();

  const validationRules = {
    password: {
      required: "This field is required.",
      minLength: {
        value: 8,
        message: "Password should be at least 8 characters.",
      },
      maxLength: {
        value: 60,
        message: "Password cannot exceed 60 characters.",
      },
    },
    confirmPassword: {
      required: "This field is required.",
      validate: {
        notEqualPassword: (value) =>
          value === getValues("password") ||
          "Password and confirm password must be the same",
      },
    },
  };

  const handleClickShowPassword = () =>
    setShowPassword((prevShowPassword) => !prevShowPassword);

  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleResetPassword = async () => {
    setLoading(true);
    try {
      await api.resetPassword({
        password: getValues("password"),
        token,
      });
      setResetPasswordSuccess(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      {pageLoading ? (
        <CircularProgress className={classes.circularProgress} />
      ) : resetPasswordSuccess || error !== null ? (
        <div className={classes.container + " " + classes.boxBackground}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                  <div className={classes.title}>
                    {resetPasswordSuccess
                      ? "Your password has been changed successfully"
                      : error}
                  </div>
                </GridItem>
              </GridContainer>

              {resetPasswordSuccess ? (
                <img
                  className={classes.resetPasswordSuccessImg}
                  src={resetPasswordSuccessImg}
                  alt="resetPasswordSuccessImg"
                />
              ) : (
                <CancelOutlinedIcon className={classes.cancelOutlinedIcon} />
              )}
            </GridItem>
          </GridContainer>
        </div>
      ) : (
        <form
          className={classes.form}
          onSubmit={handleSubmit(handleResetPassword)}
        >
          <h3>Reset your password</h3>

          <p>Please enter your new password</p>

          <CssTextField
            id="password"
            name="password"
            error={errors.password?.message !== undefined}
            type={showPassword ? "text" : "password"}
            helperText={errors.password?.message}
            inputRef={register(validationRules.password)}
            label="Password"
            variant="outlined"
            size="small"
            className={classes.textFieldMarginBottom}
            InputProps={{
              className: classes.textField,
              endAdornment:
                getValues("password") !== "" ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ) : null,
            }}
          />

          <CssTextField
            id="confirmPassword"
            name="confirmPassword"
            error={errors.confirmPassword?.message !== undefined}
            type={showPassword ? "text" : "password"}
            helperText={errors.confirmPassword?.message}
            inputRef={register(validationRules.confirmPassword)}
            label="Confirm Password"
            variant="outlined"
            size="small"
            className={classes.textFieldMarginBottom}
            InputProps={{ className: classes.textField }}
          />

          <Button
            type="submit"
            className={classes.resetPasswordButton}
            loading={loading}
          >
            Reset Password
          </Button>
        </form>
      )}
    </div>
  );
}
