import {
  createMuiTheme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button";
import CustomModal from "components/CustomModals/CustomModal/CustomModal";
import Loading from "components/Loading/Loading";
import GridContainer from "components/Grid/GridContainer";
import classNames from "classnames";
import GridItem from "components/Grid/GridItem";
import enums from "enums";
import React, { useState } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import api from "services/api";
import styles from "./paymentModalStyle";

const useStyles = makeStyles(styles);

const CssTextField = withStyles(styles(createMuiTheme()).cssTextField)(
  TextField
);

const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

export default function PaymentModal(props) {
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const classes = useStyles();
  const [promo, setPromo] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [enrollLoading, setEnrollLoading] = useState(false);

  const priceClasses = classNames({
    [classes.price]: true,
    [classes.oldPrice]: !!promo,
  });

  const { course, open, onClose, onError, selectedGroup } = props;

  const { register, handleSubmit, setError, getValues, setValue, errors } =
    useForm();

  const validationRules = {
    promo: {
      required: "This field is required.",
    },
  };

  React.useEffect(() => {
    (async () => {
      if (course) {
        try {
          const response = await api.getPromoCodeForCourse(course.id);
          setPromo(response.data);
          setValue("promo", response.data.code);
        } catch (err) {}
      }
    })();
  }, [course]);

  const handlePromo = async () => {
    try {
      setButtonLoading(true);

      if (promo) {
        await api.cancelPromoCode({ promoId: promo.id });
        setPromo(undefined);
      } else {
        const response = await api.applyPromoCode({
          promoCode: getValues("promo"),
          courseId: course.id,
        });
        setPromo(response.data);
      }
    } catch (err) {
      if (err.response?.data) {
        setError("promo", {
          type: "manual",
          message: err.response.data.message,
        });
      }
    } finally {
      setButtonLoading(false);
    }
  };

  const createOrder = async (payment_ref_id = undefined) => {
    try {
      setEnrollLoading(true);
      await api.makeOrder({
        ...(payment_ref_id ? { payment_ref_id } : {}),
        id: user.id,
        status: enums.OrderStatus.CONFIRMED,
        groups: [selectedGroup.id],
        ...(!!promo ? { promoId: promo.id, courseId: course.id } : {}),
      });
      onClose();
      setEnrollLoading(false);
      history.push({
        pathname: "paymentsuccessful",
      });
    } catch {
      setEnrollLoading(false);
      onError(
        "Payment processed successfuly but course registration failed. Please contact support."
      );
      onClose();
    }
  };

  const paypalClickHandler = async (data, actions) => {
    let coursePrice = 0;
    let discountedAmount = 0;
    try {
      const response = (await api.getCourseDetails(course.id)).data;
      coursePrice = response.price;
    } catch (err) {
      return;
    }
    try {
      const response = await api.getPromoCodeForCourse(course.id);
      discountedAmount = !!response.data
        ? response.data.type === enums.PromoType.CASH
          ? response.data.discount
          : ((coursePrice * response.data.discount) / 100).toFixed(2)
        : 0;
    } catch (err) {}
    return actions.order.create({
      intent: "CAPTURE",
      purchase_units: [
        {
          amount: {
            currency_code: "USD",
            value: `${(coursePrice - discountedAmount).toFixed(2)}`,
          },
        },
      ],
    });
  };

  const promoAmount = !!promo
    ? promo.type === enums.PromoType.CASH
      ? promo.discount
      : ((course?.price * promo.discount) / 100).toFixed(2)
    : 0;

  const canApplyPromo =
    course?.price !== null && course?.price !== undefined && course.price > 0;

  return (
    <CustomModal open={open} onClose={onClose}>
      <GridContainer className={classes.modalContainer}>
        <GridItem>
          <p className={classes.enrollInCourse}>
            Are you sure you want to enroll in this course?
          </p>
          {canApplyPromo && (
            <p className={classes.havePromo}>Have a promo code?</p>
          )}
          {canApplyPromo && (
            <form onSubmit={handleSubmit(handlePromo)}>
              <div className={classes.promoContainer}>
                <div className={classes.promoInnerContainer}>
                  <p className={classes.promoLabel}>Promo Code</p>
                  <div className={classes.inputWithButton}>
                    <CssTextField
                      id="promo"
                      key="promo"
                      name="promo"
                      error={errors.promo?.message !== undefined}
                      helperText={errors.promo?.message}
                      inputRef={register(validationRules.promo)}
                      //   label="Promo"
                      InputProps={{ placeholder: "Enter the code here" }}
                      variant="outlined"
                      size="small"
                      className={classes.textFieldMarginBottom}
                      //   InputProps={{ className: classes.textField }}
                    />
                    <Button
                      type="submit"
                      className={classes.button}
                      color="primary"
                    >
                      <div className={classes.buttonInnerContainer}>
                        {buttonLoading ? (
                          <Loading
                            loading={buttonLoading}
                            iconStyle={styles(createMuiTheme()).loadingIcon}
                          />
                        ) : promo ? (
                          "Cancel"
                        ) : (
                          "Apply"
                        )}
                      </div>
                    </Button>
                  </div>
                  {!!promo ? (
                    <span>
                      <span className={classes.promoDiscountTitle}>
                        Discount
                      </span>
                      &emsp;
                      <span className={classes.promoDiscount}>{`${
                        promo.discount
                      } ${
                        promo.type === enums.PromoType.CASH ? "USD" : "%"
                      }`}</span>
                    </span>
                  ) : null}
                </div>
              </div>
            </form>
          )}

          {/* ${(course?.price - promoAmount).toFixed(2)} USD */}
          {course?.price !== null && course?.price !== undefined ? (
            <div className={classes.orderTotalContainer}>
              <p className={classes.totalTitle}>Total</p>
              <div>
                <p className={priceClasses}>{`${course?.price.toFixed(2)}${
                  !!promo ? "" : " USD"
                }`}</p>
                {!!promo ? (
                  <p className={classes.newPrice}>{`${Math.max(
                    course?.price - promoAmount,
                    0
                  ).toFixed(2)} USD`}</p>
                ) : null}
              </div>
            </div>
          ) : null}

          {(course?.price - promoAmount).toFixed(2) > 0 ? (
            <PayPalButton
              // amount={(course?.price - promoAmount).toFixed(2)}
              // currency="USD"
              createOrder={paypalClickHandler}
              onSuccess={(details, data) => {
                (async () => {
                  await createOrder(details.id);
                })();
              }}
              onError={() => {
                onError();
                onClose();
              }}
              options={{
                clientId: clientId,
              }}
            />
          ) : (
            <div className={classes.yesNoContainer}>
              <Button className={classes.cancelButton} onClick={onClose}>
                Cancel
              </Button>
              <Button
                className={classes.enrollButton}
                color="primary"
                onClick={() => createOrder()}
              >
                <div className={classes.yesNoButtonInnerContainer}>
                  {buttonLoading ? (
                    <Loading
                      loading={enrollLoading}
                      iconStyle={styles(createMuiTheme()).loadingIcon}
                    />
                  ) : (
                    "Enroll"
                  )}
                </div>
              </Button>
            </div>
          )}
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}
