import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import christian from "assets/images/christian.jpg";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import React from "react";
import style from "./testimonialCardStyle.js";

const useStyles = makeStyles(style);

export default function TestimonialSkeleton(props) {
  const classes = useStyles();
  return (
    <div>
      <Card
        testimonial
        plain
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
        className={classes.testimonialCard}
      >
        <Skeleton variant="circle">
          <CardAvatar testimonial plain>
            <img src={christian} alt="..." />
          </CardAvatar>
        </Skeleton>
        <div>
          <Skeleton style={{ margin: "0px auto" }} variant="rect" width={200}>
            <h4 className={classes.cardTitle}>Kendall Thompson</h4>
          </Skeleton>
          <Skeleton style={{ margin: "0px auto" }} variant="rect" width="100%">
            <p className={classes.cardDescription}>
              I stand to applaud the eye-opening nature of the course. Maybe, I
              was looking for a more "academic" course initially, but this is
              was looking for a more "academic" course initially, but this is
            </p>
          </Skeleton>
          <Skeleton style={{ margin: "0px auto" }} variant="rect" width="80%">
            <h6 className={classes.job}>CEO @ Marketing Digital Ltd.</h6>
          </Skeleton>
        </div>
      </Card>
    </div>
  );
}
