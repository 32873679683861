import {
  createMuiTheme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import defaultPerson from "assets/images/default-person.png";
import profileImage from "assets/images/myprofile.png";
import CourseCard from "components/CourseCard/CourseCard.js";
import CourseCardSkeleton from "components/CourseCard/CourseCardSkeleton.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { countryPhoneCodes } from "constants/countryPhoneCodes.js";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import api from "services/api.js";
import GeneralInfoForm from "./GeneralInfoForm/GeneralInfoForm.js";
import PasswordForm from "./PasswordForm/PasswordForm.js";
import style from "./profileStyle.js";
import UploadImageModal from "./UploadImageModal/UploadImageModal.js";

const useStyles = makeStyles(style);

const CssTextField = withStyles(style(createMuiTheme()).cssTextField)(
  TextField
);

export default function Profile(props) {
  const [loading, setLoading] = React.useState(true);
  const [myCourses, setMyCourses] = React.useState([]);
  const [featuredCourses, setFeaturedCourses] = React.useState([]);
  const [showImageModal, setShowImageModal] = React.useState(false);
  const [values, setValues] = React.useState({
    fullname: "",
    email: "",
    countryCode: "",
    phone: "",
    countryIso: "ae",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const user = useSelector((state) => state.auth.user);
  const history = useHistory();

  React.useEffect(() => {
    let country = countryPhoneCodes.find((country) => {
      return country.code === user.countryCode;
    });
    setValues({
      ...values,
      fullname: user.name,
      email: user.email,
      countryCode: user.countryCode,
      phone: user.countryCode + user.phone,
      countryIso: country?.iso.toLowerCase(),
    });
  }, [user]);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    (async () => {
      try {
        setLoading(true);
        const response = (await api.getMyCourses()).data;
        const featuredAndNewCoursesResponse = (
          await api.getFeaturedAndNewCourses()
        ).data;
        setFeaturedCourses(featuredAndNewCoursesResponse.featured);
        setMyCourses(response);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    })();
  }, []);

  const handleChange = (key) => (event) =>
    setValues({ ...values, [key]: event.target.value });

  let myCoursesElements = [];

  let featuredCoursesElements = [];

  if (myCourses.length > 0) {
    myCourses.forEach((course, index) => {
      if (index < 3)
        myCoursesElements.push(<CourseCard key={index} course={course} />);
    });
  }

  if (featuredCourses.length > 0) {
    featuredCourses.forEach((course, index) => {
      if (index < 3)
        featuredCoursesElements.push(
          <CourseCard key={index} course={course} />
        );
    });
  }

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <UploadImageModal
        open={showImageModal}
        onClose={() => {
          setShowImageModal(false);
        }}
        image={user?.imgUrl}
      />
      <div className={classes.container + " " + classes.headerImageContainer}>
        <img className={classes.contactHeader} src={profileImage} />
      </div>
      <div className={classes.contactContainer}>
        <div className={classes.container + " " + classes.shadowContainer}>
          <div className={classes.section + " " + classes.sectionBorder}>
            <h3 style={{ marginBottom: 60 }} className={classes.heading}>
              Account Settings
            </h3>
            <GridContainer justify="center">
              <GridItem
                xs={12}
                sm={12}
                md={2}
                className={classes.mAuto + " " + classes.flexCenter}
              >
                {user?.imgUrl ? (
                  <img
                    src={user?.imgUrl}
                    alt={user?.name}
                    className={classes.img}
                  />
                ) : (
                  <img
                    src={defaultPerson}
                    alt={user?.name}
                    className={classes.img}
                  />
                )}
                <Button
                  onClick={() => {
                    setShowImageModal(true);
                  }}
                  color="primary"
                  className={classes.button}
                  round
                  // loading={loading}
                >
                  Upload Image
                </Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} className={classes.mAuto}>
                <h4 style={{ marginBottom: 20 }}>General</h4>
                <GeneralInfoForm user={user ? user : null} />
              </GridItem>
              <GridItem xs={12} sm={12} md={4} className={classes.mAuto}>
                <h4 style={{ marginBottom: 20 }}>Password</h4>
                <PasswordForm />
              </GridItem>
            </GridContainer>
          </div>
          <GridContainer justify="center" className={classes.coursesSection}>
            <GridItem xs={12} sm={12} md={12} className={classes.bg}>
              <div className={classes.section}>
                <h3 className={classes.heading}>My Courses</h3>
                {loading ? (
                  <GridContainer className={classes.coursesContainer}>
                    <CourseCardSkeleton />
                    <CourseCardSkeleton />
                    <CourseCardSkeleton />
                  </GridContainer>
                ) : (
                  <GridContainer className={classes.coursesContainer}>
                    {myCoursesElements.length > 0 ? (
                      myCoursesElements
                    ) : (
                      <span className={classes.noCoursesMessage}>
                        You are not in enrolled in any courses.{" "}
                        <Link
                          className={classes.gotoCoursesLink}
                          to={"courses"}
                        >
                          Go to courses
                        </Link>{" "}
                        to enroll in your first course.
                      </span>
                    )}
                  </GridContainer>
                )}
                <div className={classes.buttonContainer}>
                  {myCoursesElements.length > 0 ? (
                    <Button
                      onClick={() => {
                        history.push({
                          pathname: "mycourses",
                        });
                      }}
                      color="primary"
                      className={classes.button}
                      round
                    >
                      See all
                    </Button>
                  ) : null}
                </div>
              </div>
              <div className={classes.section}>
                <h3 className={classes.heading}>Featured Courses</h3>
                {loading ? (
                  <GridContainer className={classes.coursesContainer}>
                    <CourseCardSkeleton />
                    <CourseCardSkeleton />
                    <CourseCardSkeleton />
                  </GridContainer>
                ) : (
                  <GridContainer className={classes.coursesContainer}>
                    {featuredCoursesElements}
                  </GridContainer>
                )}
                <div className={classes.buttonContainer}>
                  <Button
                    onClick={() => {
                      history.push({
                        pathname: "courses",
                      });
                    }}
                    color="primary"
                    className={classes.button}
                    round
                  >
                    Browse more courses
                  </Button>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
