import {
  whiteColor,
  primaryColor,
  container,
} from "assets/jss/material-kit-pro-react";
const courseDetailsStyle = (theme) => ({
  root: {
    padding: "0px 0px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "60px 0px",
      paddingBottom: 0,
    },
  },
  container: {
    ...container,
    zIndex: 1,
  },
  headerImageContainer: {
    minHeight: 185,
    [theme.breakpoints.down("sm")]: {
      minHeight: 0,
    },
  },
  coursesHeaderImage: {
    width: "100%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "none",
      minHeight: 0,
    },
  },
  instructorName: {
    color: primaryColor[0],
  },
  courseImage: {
    width: "100%",
    margin: "auto",
  },
  shadowContainer: {
    marginTop: 60,
    marginBottom: 60,
    boxShadow: "0 2.5px 25px 0 rgba(0, 0, 0, 0.5)",
    width: "90%",
    borderRadius: 32.5,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
      width: "100%",
      borderRadius: 0,
    },
  },
  courseDataContainer: {
    margin: "30px 0px",
    padding: "40px 70px",
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      padding: "0px 0px",
      paddingBottom: 20,
    },
  },
  pageTitle: {
    textAlign: "center",
    opacity: "0.75",
    fontWeight: "bold",
    color: "#2f2f2f",
    fontSize: "28px",
    paddingTop: 60,
  },
  title: {
    fontSize: "24px",
    color: "#2f2f2f",
    margin: "30px 0px",
    marginTop: 0,
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#2f2f2f",
    marginBottom: "20px",
  },
  text: {
    fontSize: "16px",
    marginBottom: "20px",
    whiteSpace: "break-spaces",
  },
  date: {
    fontSize: "16px",
    marginBottom: "20px",
    minWidth: 150,
  },
  imageConatainer: {
    width: "100%",
    height: "350px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    position: "relative",
  },
  image: {
    borderRadius: "5px",
    position: "absolute",
    top: "8px",
    right: "16px",
    flexShrink: "0",
    maxWidth: "90%",
    maxHeight: "80%",
  },
  ratingStar: {
    fill: primaryColor[0],
  },
  courseDetailsCard: {
    boxShadow: "0 0 5px 0",
    padding: "20px",
    margin: "20px 0px",
  },
  center: {
    textAlign: "center",
  },
  button: {
    padding: "6px 15px",
    "& > span:first-child": {
      color: whiteColor,
      fontSize: 14,
    },
  },
  bold: {
    fontWeight: "bold",
  },
  courseDetailsCardText: {
    fontSize: "18px",
    color: "#2f2f2f",
    margin: "20px 0px",
  },
  zoomImage: {
    height: "100px",
    objectFit: "contain",
  },
  paymentImage: {
    width: "100%",
    maxWidth: 300,
    maxHeight: 75,
    objectFit: "contain",
  },
  moneyBackImage: {
    height: "200px",
    objectFit: "contain",
  },
  share: {
    position: "absolute",
    right: "8px",
    bottom: "30px",
  },
  whatYouWilLearnContainer: {
    margin: "30px 0px",
    padding: "20px 70px",
    paddingTop: 0,
    // borderBottom: "1px solid #aaa",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 0px",
      paddingBottom: 20,
    },
  },
});

export default courseDetailsStyle;
