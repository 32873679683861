import Footer from "components/Footer/Footer";
import Header from "components/Header/Header.js";
import { createBrowserHistory } from "history";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Router, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import { authCheckState } from "store/actions";
import About from "views/About/About";
import ContactUsPage from "views/ContactUsPage/ContactUsPage";
import CourseDetails from "views/CourseDetails/CourseDetails";
import Courses from "views/Courses/Courses";
import EmailConfirmation from "views/EmailConfirmation/EmailConfirmation";
import EmailVerification from "views/EmailVerification/EmailVerification";
import Home from "views/Home/Home";
import MyCourses from "views/MyCourses/MyCourses";
import PaymentSuccess from "views/PaymentSuccess/PaymentSuccess";
import PreviousCourses from "views/PreviousCourses/PreviousCourses";
import PrivacyPolicy from "views/PrivacyPolicy/PrivacyPolicy";
import Profile from "views/Profile/Profile";
import ResetPassword from "views/ResetPassword/ResetPassword";
import TermsAndConditions from "views/TermsAndConditions/TermsAndConditions";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./assets/scss/material-kit-pro-react.scss?v=1.9.0";
import "./App.css";

var hist = createBrowserHistory();

const App = () => {
  const dispatch = useDispatch();
  const [authLoading, setAuthLoading] = React.useState(true);
  const user = useSelector((state) => state.auth.user);

  React.useEffect(() => {
    const authAutoSignIn = async () => {
      await dispatch(authCheckState());
      setAuthLoading(false);
    };
    authAutoSignIn();
  }, [dispatch]);

  let commonRoutes = [
    <Route
      key={Math.random()}
      path="/auth/email/confirm"
      component={EmailConfirmation}
    />,
    <Route
      key={Math.random()}
      path="/about"
      render={(props) => <About {...props} key={Math.random()} />}
    />,
    <Route
      key={Math.random()}
      path="/course"
      render={(props) => <CourseDetails {...props} key={Math.random()} />}
    />,
    <Route
      key={Math.random()}
      path="/contact"
      render={(props) => <ContactUsPage {...props} key={Math.random()} />}
    />,
    <Route
      key={Math.random()}
      path="/courses"
      render={(props) => <Courses {...props} key={Math.random()} />}
    />,
    <Route
      key={Math.random()}
      path="/termsofuse"
      component={TermsAndConditions}
    />,
    <Route
      key={Math.random()}
      path="/privacypolicy"
      component={PrivacyPolicy}
    />,
    <Route
      key={Math.random()}
      exact
      path="/"
      render={(props) => <Home {...props} key={Math.random()} />}
    />,
  ];

  const routes = (
    <Switch>
      {commonRoutes}
      {!!user ? (
        [
          <Route
            key={Math.random()}
            path="/profile"
            render={(props) => <Profile {...props} key={Math.random()} />}
          />,
          <Route
            key={Math.random()}
            path="/mycourses"
            render={(props) => <MyCourses {...props} key={Math.random()} />}
          />,
          <Route
            key={Math.random()}
            path="/paymentsuccessful"
            component={PaymentSuccess}
          />,
          <Route
            key={Math.random()}
            path="/previouscourses"
            render={(props) => (
              <PreviousCourses {...props} key={Math.random()} />
            )}
          />,
        ]
      ) : (
        <Route
          key={Math.random()}
          path="/resetpassword"
          component={ResetPassword}
        />
      )}
      <Redirect to="/" />
    </Switch>
  );

  return (
    <Router history={hist}>
      {authLoading ? (
        <div className="loading-container">
          <CircularProgress className="circular-progress" />
        </div>
      ) : (
        <Switch>
          <Route path="/auth/email/verify" component={EmailVerification} />
          {/* {authLoading ? (
          <div className="loading-container">
            <CircularProgress className="circular-progress" />
          </div>
        ) : ( */}
          <Fragment>
            <Header
              absolute
              color="transparent"
              changeColorOnScroll={{
                color: "white",
              }}
            />
            {routes}
            <Footer />
          </Fragment>
          {/* )} */}
        </Switch>
      )}
    </Router>
  );
};

export default App;
