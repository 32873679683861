import banner1 from "assets/images/banner1.png";
import bg1 from "assets/images/bg-home-1.png";
import { primaryColor } from "assets/jss/material-kit-pro-react";
import {
  container,
  mlAuto,
  mrAuto,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react.js";

const homeStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  root: {
    padding: "60px 0px",
    position: "relative",
  },
  container: {
    ...container,
    zIndex: 1,
  },
  shadowContainer: {
    boxShadow: "0 2.5px 25px 0 rgba(0, 0, 0, 0.5)",
    width: "90%",
    borderRadius: 32.5,
    backgroundImage: `url(${bg1})`,
    backgroundSize: "contain",
    backgroundPosition: "top center",
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
      width: "100%",
      borderRadius: 0,
      backgroundImage: "none",
    },
  },
  homeParagraph: {
    fontSize: 20,
    color: "white",
    fontWeight: 100,
  },
  break: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  title: {
    ...title,
    "&, & + h4": {
      color: whiteColor,
    },
  },
  textCenter: {
    textAlign: "center",
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative",
  },
  rowContainer: {
    padding: "0px 50px",
    marginTop: "150px",
    marginBottom: "300px",
  },
  button: {
    position: "relative",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "14px 28px",
    lineHeight: "20px",
    textDecoration: "none",
    textTransform: "capitalize",
    display: "inline-flex",
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontSize: "0.75rem",
      "& > span:first-child": {
        justifyContent: "center",
      },
    },
  },
  subscribe: {
    borderRadius: 3,
    padding: "5px 28px",
    marginTop: -5,
    display: "flex",
    justifyContent: "center",
    "& > span:first-child": {
      color: whiteColor,
      fontSize: 16,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
  },
  banner: {
    width: "100%",
    height: "fit-content",
    minHeight: 200,
    position: "absolute",
    left: 0,
  },
  banner1Container: {
    height: 240,
    [theme.breakpoints.down("sm")]: {
      height: 320,
    },
  },
  subscribeSuccessMessage: {
    color: primaryColor[0],
    fontSize: 14,
    fontWeight: 1000,
    backgroundColor: "rgba(0,0,0,0.3)",
    paddingLeft: 5,
    opacity: 0,
    transition: "0.3s all",
  },
  subscribeSuccessMessageVisible: {
    opacity: 1,
  },
  banner1Image: {
    backgroundImage: `linear-gradient(
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.7)
    ),url(${banner1})`,
    backgroundSize: "cover",
    backgroundPosition: "top center",
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
  },
  parallax: {
    backgroundImage: `linear-gradient(to bottom right, rgba(255, 255, 255, 0.5) 10%, rgba(0, 0, 0, 0.4) 30%, rgba(0, 0, 0, 0.5)), url(${require("../../assets/images/home-background.jpg")})`,
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 13%, rgba(0, 0, 0, 0.4) 30%, rgba(0, 0, 0, 0.5)), url(${require("../../assets/images/home-background.jpg")})`,
      backgroundPosition: "10% center"
    },
  },
  stayInTouch: {
    display: "flex",
    flexDirection: "column",
  },
  stayInTouchText: {
    color: whiteColor,
  },
  stayInTouchHeader: {
    marginTop: 40,
    color: whiteColor,
  },
  subscribeForm: {
    display: "flex",
    flexDirection: "column",
    marginTop: 35,
    marginBottom: 15,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  kaisBanner: {
    position: "relative",
    height: "fit-content",
    minHeight: 200,
    paddingLeft: 20,
    paddingRight: 320,
    paddingTop: 20,
    [theme.breakpoints.down("sm")]: {
      paddingRight: 20,
    },
  },
  kais: {
    position: "absolute",
    height: 380,
    right: 0,
    marginRight: 70,
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  kaisQuote: {
    color: whiteColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem",
    },
  },
  kaisText: {
    color: whiteColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  banner2Container: {
    height: 200,
    [theme.breakpoints.down("xs")]: {
      height: 260,
    },
  },
});

export default homeStyle;
