import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import strings from "constants/strings";
import React, { useEffect } from "react";
import privacyPolicyPageStyle from "./privacyPolicyStyle";

const useStyles = makeStyles(privacyPolicyPageStyle);

export default function PrivacyPolicy({ ...rest }) {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className={classes.pageHeader}>
        <GridContainer justify="center" className={classes.termsHeader}>
          <GridItem xs={12} sm={12} md={12}>
            <h1>{strings.privacyPolicy}</h1>
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <p className={classes.termBody}>{strings.privacyPolicyParagraph}</p>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <h3 className={classes.termsHeading}>
              {strings.howWeCollectDataAboutYou}
            </h3>
            <p className={classes.termBody}>
              {strings.howWeCollectDataAboutYouParagraph}
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
