import {
  createMuiTheme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button.js";
import PhoneInput from "components/TextInputs/PhoneInput/PhoneInput";
import {
  countryPhoneCodes,
  phoneCodeToCountry,
} from "constants/countryPhoneCodes.js";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { updateStudentInfo } from "store/actions/auth.js";
import validator from "validator";
import style from "./generalInfoFormStyle.js";

const useStyles = makeStyles(style);

const CssTextField = withStyles(style(createMuiTheme()).cssTextField)(
  TextField
);

export default function GeneralInfoForm(props) {
  const { user } = props;
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [countrySelect, setCountrySelect] = React.useState(
    user?.country || 225
  );
  const [responseMessage, setResponseMessage] = React.useState({
    message: ".",
    visible: false,
    error: false,
  });

  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setValue,
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      phone:
        user?.countryCode && user?.phone
          ? user?.countryCode.replace("+", "").replace(/\-/g, "") + user?.phone
          : "971",
      fullname: user?.name,
    },
  });

  const validationRules = {
    fullname: {
      required: "This field is required.",
      pattern: {
        value: /^(\w.+\s).+$/,
        message: "Please insert your full name",
      },
    },
    phone: {
      validate: {
        phone: (value) => {
          if (phoneCodeToCountry[value] !== undefined) return true; // if (!value) return true;
          return (
            validator.isMobilePhone(
              `+${value.toString().replace(/[^0-9]/g, "")}`
            ) || "Phone number not valid"
          );
        },
      },
    },
  };
  const handleUpdateInfo = async () => {
    try {
      setLoading(true);
      let info = {
        name: getValues("fullname"),
      };

      if (getValues("phone")) {
        let countryCode = countryPhoneCodes[countrySelect].code
          .replace("+", "")
          .replace(/\-/g, "");
        info.country = countrySelect;
        info.countryCode = `+${countryCode}`;
        info.phone = getValues("phone")
          .replace(countryCode, "")
          .replace(/[^0-9]/g, "");
      }

      await dispatch(updateStudentInfo(info));
      setLoading(false);
      setTimeout(() => {
        setResponseMessage({
          visible: true,
          error: false,
          message: "Information updated successfully",
        });
      }, 1000);
      setTimeout(() => {
        setResponseMessage({
          ...responseMessage,
          visible: false,
          error: false,
        });
      }, 5000);
    } catch (err) {
      setLoading(false);
      setTimeout(() => {
        setResponseMessage({
          visible: true,
          error: true,
          message: "Something went wrong",
        });
      }, 1000);
      setTimeout(() => {
        setResponseMessage({
          ...responseMessage,
          visible: false,
          error: false,
        });
      }, 5000);
    }
  };

  const classes = useStyles();
  return (
    <form className={classes.form} onSubmit={handleSubmit(handleUpdateInfo)}>
      <CssTextField
        id="fullname"
        key="fullname"
        name="fullname"
        error={errors.fullname?.message !== undefined}
        helperText={errors.fullname?.message}
        inputRef={register(validationRules.fullname)}
        label="Full name"
        variant="outlined"
        size="small"
        className={classes.textFieldMarginBottom}
        InputProps={{ className: classes.textField }}
        onChange={(event) => {
          setValue("fullname", event.target.value);
        }}
        defaultValue={getValues("fullname")}
      />
      <Controller
        render={(props) => (
          <PhoneInput
            id="phone"
            error={errors.phone?.message !== undefined}
            helperText={errors.phone?.message}
            inputRef={register(validationRules.phone)}
            label="Phone"
            variant="outlined"
            size="small"
            value={props.value}
            setValue={setValue}
            className={classes.textFieldMarginBottom + " " + classes.phoneInput}
            InputProps={{
              className: classes.textField,
            }}
            country={countrySelect}
            setCountry={setCountrySelect}
          />
        )}
        key="phone"
        name="phone"
        defaultValue=""
        control={control}
        rules={validationRules.phone}
      />
      <div
        className={
          classes.responseMessage +
          " " +
          classes.marginBottomDesktop +
          " " +
          (responseMessage.visible
            ? classes.responseMessageVisible + " "
            : "") +
          (responseMessage.error ? classes.dangerText : "")
        }
      >
        {responseMessage.message}
      </div>
      <div className={classes.saveButton}>
        <Button
          type="submit"
          color="primary"
          className={classes.button}
          round
          loading={loading}
        >
          Save
        </Button>
      </div>
    </form>
  );
}
