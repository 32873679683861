import { dangerColor } from "assets/jss/material-kit-pro-react";
import { secondaryColor } from "assets/jss/material-kit-pro-react";
import {
  blackColor,
  whiteColor,
  primaryColor,
} from "assets/jss/material-kit-pro-react";

const paymentModalStyle = (theme) => ({
  modalContainer: {
    margin: 0,
    padding: "20px",
    [theme.breakpoints.up("sm")]: {
      minWidth: 600,
    },
  },
  button: {
    borderRadius: "0 3px 3px 0",
    padding: "10.25px 15px",
    "& > span:first-child": {
      color: whiteColor,
      fontSize: 14,
    },
    margin: 0,
    maxHeight: 40.5,
  },
  cssTextField: {
    root: {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "& label.MuiFormLabel-root": {
        marginTop: -3,
      },
      "& label.Mui-focused": {
        color: "#8c7e4c",
        marginTop: 0,
      },
      "& label.MuiFormLabel-filled": {
        marginTop: 0,
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#8c7e4c",
      },
      "& .MuiOutlinedInput-root": {
        // width: 180,
        width: "100%",
        height: 40.1,
        //   "& fieldset": {
        //     borderColor: "white",
        //   },
        //   "&:hover fieldset": {
        //     borderColor: "white",
        //   },
        borderRadius: "3px 0 0 3px",
        "&.Mui-focused fieldset": {
          // borderColor: "#8c7e4c",
          // borderColor: "none",
          border: "1px solid #8c7e4c",
        },
      },
    },
  },
  havePromo: {
    color: "black",
    fontSize: 12,
    fontWeight: 100,
  },
  promoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 120,
    border: "0.5px solid black",
    borderRadius: 10,
    //   width: 300,
    width: "100%",
    marginBottom: 20,
  },
  promoInnerContainer: { width: "100%", padding: 20 },
  promoLabel: { color: "black", fontSize: 14, fontWeight: "bold", margin: 0 },
  inputWithButton: { display: "flex", margin: "10px 0px" },
  buttonInnerContainer: { width: 57, height: 20 },
  loadingIcon: {
    color: "white",
    margin: 0,
    display: "flex",
    width: "unset",
    height: "unset",
  },
  promoDiscountTitle: {
    color: "#009C05",
    fontSize: 12,
    fontWeight: "bold",
    margin: 0,
  },
  promoDiscount: {
    color: secondaryColor[0],
    fontSize: 12,
    fontWeight: "bold",
    margin: 0,
  },
  orderTotalContainer: { display: "flex", marginBottom: 10 },
  totalTitle: {
    color: blackColor,
    fontSize: 16,
    fontWeight: "bold",
    margin: "0 15px 0 0",
  },
  price: {
    color: blackColor,
    fontSize: 14,
    fontWeight: "bold",
    margin: 0,
  },
  oldPrice: {
    color: dangerColor[0],
    textDecoration: "line-through",
    margin: 0,
  },
  newPrice: {
    color: blackColor,
    fontSize: 14,
    fontWeight: "bold",
    margin: 0,
  },
  slantedDiv: {
    marginLeft: 50,
    position: "relative",
    display: "inline-block",
    padding: "1em 5em 1em 1em",
    overflow: "hidden",
    color: "#fff",
    content: "",
    width: 150,
    height: 50,
    background: "#000",
    "-webkit-transform-origin": "100% 0",
    "-ms-transform-origin": "100% 0",
    "transform-origin": "100% 0",
    "-webkit-transform": "skew(-45deg)",
    "-ms-transform": "skew(-45deg)",
    transform: "skew(-45deg)",
    // zIndex: -1,
    // },
  },
  yesNoContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  enrollButton: {
    borderRadius: 3,
    padding: "10.25px 15px",
    "& > span:first-child": {
      color: whiteColor,
      fontSize: 14,
    },
    margin: 0,
    maxHeight: 40.5,
    width: "45%",
  },
  cancelButton: {
    backgroundColor: "#2F2F2F",
    border: `0.5px solid black`,
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.8)",
    },
    borderRadius: 3,
    padding: "10.25px 15px",
    "& > span:first-child": {
      color: whiteColor,
      fontSize: 14,
    },
    margin: 0,
    maxHeight: 40.5,
    width: "45%",
  },
  yesNoButtonInnerContainer: { width: "100%", height: 20 },
  enrollInCourse: {
    color: "black",
    fontSize: 20,
    textAlign: "center",
    fontWeight: 400,
  },
});

export default paymentModalStyle;
