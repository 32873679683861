import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/`,
  withCredentials: true,
});

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     const originalRequest = error.config;

//     if (
//       error.response.status === 401 &&
//       originalRequest.url === `students/token`
//     ) {
//       // router.push("/");
//       return Promise.reject(error);
//     } else if (
//       error.response.status === 401 &&
//       originalRequest.url === `students/signin`
//     ) {
//       return Promise.reject(error);
//     }

//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       const refreshToken = localStorage.getItem("refreshToken");
//       return axiosInstance
//         .post("students/token", { refreshToken })
//         .then((res) => {
//           if (res.status === 201) {
//             const { accessToken, refreshToken, ...user } = res.data;
//             localStorage.setItem("user", JSON.stringify(user));
//             localStorage.setItem("accessToken", accessToken);
//             localStorage.setItem("refreshToken", refreshToken);
//             setAuthToken(accessToken);
//             return axiosInstance(originalRequest);
//           }
//         });
//     }
//     return Promise.reject(error);
//   }
// );

const setAuthToken = (token) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const clearAuthToken = () => {
  axiosInstance.defaults.headers.common.Authorization = "";
};

const getFeaturedCourses = () => axiosInstance.get("courses/featured");

const getFeaturedAndNewCourses = () =>
  axiosInstance.get("courses/featured-new");

const getCourses = () => axiosInstance.get("courses");

const getMyCourses = () => axiosInstance.get("courses/enrolled");

const getCourseDetails = (id) => axiosInstance.get(`courses/${id}`);

const subscribeToNewsletter = (email) =>
  axiosInstance.post(`newsletter/subscribe`, { email });

const contactUs = (body) => axiosInstance.post(`contactus/`, body);

const getOurTeam = () => axiosInstance.get(`aboutus/team`);

const getOurClients = () => axiosInstance.get(`aboutus/clients`);

const getTestimonials = (courseId = undefined) => {
  let queryString = "";
  if (courseId !== undefined) {
    queryString = `?courseId=${courseId}`;
  }
  return axiosInstance.get(`aboutus/testimonials${queryString}`);
};

const signInStudent = (signInInfo) =>
  axiosInstance.post(`students/signin`, signInInfo);

const signUpStudent = (signUpInfo) =>
  axiosInstance.post(`students/signup`, signUpInfo);

const verifyEmail = (token) =>
  axiosInstance.patch(`students/email/verify`, { token });

const getCurrentUser = () => axiosInstance.get(`students/current_user`);

const logout = () => axiosInstance.get(`students/logout`);

const checkResendEmailConfirmation = () =>
  axiosInstance.get(`students/email/checkresend`);

const resendEmailConfirmation = () =>
  axiosInstance.get(`students/email/resendconfirmation`);

const uploadStudentImage = (image) =>
  axiosInstance.patch(`students/image`, { image });

const getOrdersofCurrentUser = (page) =>
  axiosInstance.get(`orders?page=${page}`);

const updateStudentInfo = (info) => axiosInstance.patch(`students/info`, info);

const forgotPassword = (email) =>
  axiosInstance.put(`students/forgotpassword`, { email });

const resetPassword = (body) =>
  axiosInstance.put(`students/resetpassword`, body);

const checkResetPassword = (token) =>
  axiosInstance.get(`students/checkresetpassword?token=${token}`);

const updateStudentPassword = (info) =>
  axiosInstance.patch(`students/password`, info);

const makeOrder = (order) => axiosInstance.post("orders", order);

const applyPromoCode = (data) => axiosInstance.put("promos/apply", data);

const cancelPromoCode = (data) => axiosInstance.patch("promos/cancel", data);

const getPromoCodeForCourse = (courseId) =>
  axiosInstance.get(`promos/course/${courseId}`);

export default {
  setAuthToken,
  clearAuthToken,
  getFeaturedCourses,
  getFeaturedAndNewCourses,
  getCourses,
  getCourseDetails,
  subscribeToNewsletter,
  getOurTeam,
  contactUs,
  getOurClients,
  getTestimonials,
  signInStudent,
  signUpStudent,
  verifyEmail,
  getCurrentUser,
  logout,
  checkResendEmailConfirmation,
  resendEmailConfirmation,
  uploadStudentImage,
  getOrdersofCurrentUser,
  getMyCourses,
  updateStudentInfo,
  forgotPassword,
  resetPassword,
  checkResetPassword,
  updateStudentPassword,
  makeOrder,
  applyPromoCode,
  cancelPromoCode,
  getPromoCodeForCourse,
};
