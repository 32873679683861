import {
  container,
  mlAuto,
  mrAuto,
  textColor,
  primaryColor,
} from "assets/jss/material-kit-pro-react.js";
import certificateBg from "assets/images/certificate-diploma.png";
import { whiteColor } from "assets/jss/material-kit-pro-react";

const profileStyle = (theme) => ({
  mrAuto,
  mlAuto,

  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  flexCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  profileName: {
    fontSize: 16,
  },
  root: {
    padding: "0px 0px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "60px 0px",
    },
  },
  container: {
    ...container,
    zIndex: 1,
  },
  headerImageContainer: {
    minHeight: 184,
    [theme.breakpoints.down("sm")]: {
      minHeight: 0,
    },
  },
  shadowContainer: {
    boxShadow: "0 2.5px 25px 0 rgba(0, 0, 0, 0.5)",
    width: "90%",
    borderRadius: 32.5,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
      width: "100%",
      borderRadius: 0,
    },
  },
  contactHeader: {
    width: "100%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contactContainer: {
    marginTop: 60,
    marginBottom: 60,
  },
  coursesSection: {
    marginTop: 20,
    marginBottom: 20,
    backgroundSize: "50%",
    backgroundPosition: "50% 20%",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${certificateBg})`,
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none",
    },
  },
  section: {
    padding: "40px 40px",
  },
  sectionBorder: {
    borderBottom: "1px solid #aaa",
  },
  heading: {
    textAlign: "center",
    color: textColor[0],
    textTransform: "uppercase",
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
      fontSize: "1.5rem",
    },
  },
  responseMessage: {
    color: primaryColor[0],
    fontSize: 14,
    fontWeight: 100,
    paddingLeft: 5,
    opacity: 1,
    transition: "0.3s all",
  },
  responseMessageVisible: {
    opacity: 1,
  },
  coursesContainer: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  button: {
    display: "flex",
    justifyContent: "center",
    padding: "7px 30px",
    borderRadius: 5,
    "& > span:first-child": {
      color: whiteColor,
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  saveButton: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  buttonContainer: {
    width: "100%",
    marginTop: 30,
    display: "flex",
    justifyContent: "center",
  },
  img: {
    width: "150px !important",
    height: "150px !important",
    borderRadius: "50%",
    objectFit: "cover",
    objectPosition: "0% 30%",
    boxShadow:
      "0 16px 38px -20px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    marginBottom: 20,
  },
  noCoursesMessage: {
    textAlign: "center",
    color: textColor[0],
    fontSize: 20,
    margin: "40px auto",
    marginBottom: 0,
  },
  gotoCoursesLink: {
    color: primaryColor[0],
    "&:hover": {
      color: primaryColor[0],
    },
  },
  marginBottomDesktop: {
    marginBottom: 105,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },
  textFieldMarginBottom: {
    marginBottom: 10,
  },
  rootContainer: {
    width: "100%",
  },
  textField: {
    width: "100%",
    height: 35,
  },
  cssTextField: {
    root: {
      width: "100%",
      marginTop: 20,
      "& label.MuiFormLabel-root": {
        marginTop: -3,
      },
      "& label.Mui-focused": {
        color: "#8c7e4c",
        marginTop: 0,
      },
      "& label.MuiFormLabel-filled": {
        marginTop: 0,
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#8c7e4c",
      },
      "& .MuiOutlinedInput-root": {
        //   "& fieldset": {
        //     borderColor: "white",
        //   },
        //   "&:hover fieldset": {
        //     borderColor: "white",
        //   },
        "&.Mui-focused fieldset": {
          borderColor: "#8c7e4c",
        },
      },
    },
  },
  phoneNumber: {
    // "& label.Mui-focused": {
    //   color: "#8c7e4c",
    // },
    width: "100%",
    marginTop: 20,
    "& label.MuiFormLabel-root": {
      marginTop: -3,
    },
    "& label.Mui-focused": {
      color: "#8c7e4c",
      marginTop: 0,
    },
    "& label.MuiFormLabel-filled": {
      marginTop: 0,
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#8c7e4c",
    },
    "& .MuiOutlinedInput-root": {
      //   "& fieldset": {
      //     borderColor: "white",
      //   },
      //   "&:hover fieldset": {
      //     borderColor: "white",
      //   },
      width: "100%",
      height: 35,
      "&.Mui-focused fieldset": {
        borderColor: "#8c7e4c",
      },
    },
  },
});

export default profileStyle;
