import {
  container,
  mlAuto,
  mrAuto
} from "assets/jss/material-kit-pro-react.js";

const contactUsPageStyle = (theme) => ({
  mrAuto,
  mlAuto,

  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  root: {
    padding: "0px 0px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "60px 0px",
    },
  },
  headerImageContainer: {
    minHeight: 185,
    [theme.breakpoints.down("sm")]: {
      minHeight: 0,
    },
  },
  container: {
    ...container,
    zIndex: 1,
  },
  shadowContainer: {
    boxShadow: "0 2.5px 25px 0 rgba(0, 0, 0, 0.5)",
    width: "90%",
    borderRadius: 32.5,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
      width: "100%",
      borderRadius: 0,
    },
  },
  contactHeader: {
    width: "100%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contactContainer: {
    marginTop: 60,
    marginBottom: 60,
  },
});

export default contactUsPageStyle;
