export default {
  AuthMode: {
    SIGNUP: 0,
    LOGIN: 1,
  },
  EmailVerificationStatus: {
    INVALID: 0,
    VERIFIED: 1,
    ALREADY_VERIFIED: 2,
    EXPIRED: 3,
  },
  OrderStatus: {
    UNCONFIRMED: 0,
    CONFIRMED: 1,
  },
  PromoType: {
    CASH: 0,
    PERCENTAGE: 1,
  },
};
