import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
// @material-ui/core components
import {
  createMuiTheme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  countryPhoneCodes,
  phoneCodeToCountry,
} from "constants/countryPhoneCodes";
import React from "react";
// core components
import styles from "./phoneInputStyle";

const useStyles = makeStyles(styles);

const CssTextField = withStyles(styles(createMuiTheme()).cssTextField)(
  TextField
);

export default function PhoneInput(props) {
  const {
    selectStyle,
    setValue,
    value,
    country,
    setCountry,
    className,
    InputProps,
    ...rest
  } = props;

  const classes = useStyles();

  const lang = "en";

  const countries = countryPhoneCodes.sort((a, b) =>
    a[lang].toLowerCase().localeCompare(b[lang].toLowerCase())
  );

  const countryPhoneCodesMenuItems = countries.map((country, index) => (
    <li key={`countryCode-${index}`} value={index} className={classes.menuItem}>
      <img
        src={require(`assets/images/country-flags/${country.iso.toLowerCase()}.png`)}
        style={{ height: 18, width: 18 }}
      />
      &nbsp;
      <div>{country[lang]}</div>
      &nbsp;
      <div style={{ width: "30%" }}>
        (
        {lang === "en"
          ? country.code
          : country.code.substr(1, country.code.length) + "+"}
        )
      </div>
    </li>
  ));

  const handleCountryCodeSelect = (event) => {
    setCountry((prevCountry) => {
      const oldPhoneCode = countryPhoneCodes[prevCountry].code
        .replace("+", "")
        .replace(/\-/g, "")
      const newPhoneCode = countryPhoneCodes[event.target.value].code
        .replace("+", "")
        .replace(/\-/g, "")
      setValue(
        "phone",
        value.indexOf(oldPhoneCode) !== -1
          ? value.replace(oldPhoneCode, newPhoneCode)
          : newPhoneCode
      );
      return event.target.value;
    });
  };

  const phoneCodeFound = (phone) => {
    const phoneCodes = Object.keys(phoneCodeToCountry);
    let phoneCode = null;
    for (let i = 0; i < phoneCodes.length; i++) {
      if (phone.startsWith(phoneCodes[i])) {
        phoneCode = phoneCodeToCountry[phoneCodes[i]];
        break;
      }
    }
    return phoneCode;
  };

  const handleChange = (event) => {
    setValue("phone", event.target.value);
    const phoneCode = phoneCodeFound(event.target.value);
    if (
      phoneCode !== null &&
      countryPhoneCodes[phoneCode].code !== countryPhoneCodes[country].code // For similar phone codes
    ) {
      setCountry(phoneCode);
    }
  };

  const phoneCodeAdornment = (
    <InputAdornment position="end" className={classes.inputAdornment}>
      <Select
        disableUnderline
        className={classes.codeSelect}
        style={{
          borderLeft: 0,
          ...selectStyle,
        }}
        value={country}
        renderValue={() => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require(`assets/images/country-flags/${countries[
                country
              ].iso.toLowerCase()}.png`)}
              style={{ height: 18, width: 18, textAlign: "center" }}
            />
            &nbsp;+
          </div>
        )}
        onChange={handleCountryCodeSelect}
        inputProps={{
          name: "country-select",
          id: "country-select",
        }}
        IconComponent={() => null}
      >
        {countryPhoneCodesMenuItems}
      </Select>
    </InputAdornment>
  );

  return (
    <CssTextField
      onChange={handleChange}
      value={value}
      className={classes.textFieldMarginBottom + " " + className}
      InputProps={{
        // className: classes.textField,
        ...InputProps,
        startAdornment: phoneCodeAdornment,
      }}
      {...rest}
    />
  );
}
