import { grayColor } from "assets/jss/material-kit-pro-react";

const phoneInputStyle = (theme) => ({
  inputAdornment: {
    position: "relative",
  },
  codeSelect: {
    borderRadius: 0,
    borderTop: 0,
    borderBottom: 0,
    "& .MuiSelect-select.MuiSelect-select": { padding: 0 },
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    padding: 10,
    "&:hover": {
      backgroundColor: grayColor[12],
      cursor: "pointer",
    },
  },
  textField: {
    width: 260,
    height: 35,
    paddingLeft: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textFieldMarginBottom: {
    marginBottom: 10,
  },
  cssTextField: {
    root: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "& label.Mui-focused": {
        color: "#8c7e4c",
        marginTop: 0,
      },
      "& label.MuiFormLabel-filled": {
        marginTop: 0,
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#8c7e4c",
      },
      "& .MuiOutlinedInput-root": {
        //   "& fieldset": {
        //     borderColor: "white",
        //   },
        //   "&:hover fieldset": {
        //     borderColor: "white",
        //   },
        "&.Mui-focused fieldset": {
          borderColor: "#8c7e4c",
        },
      },
      "& .MuiOutlinedInput-inputMarginDense": {
        paddingTop: "8px !important",
        paddingBottom: "8px !important",
      },
    },
  },
});

export default phoneInputStyle;
