import { whiteColor } from "assets/jss/material-kit-pro-react";
import { textColor, warningColor } from "assets/jss/material-kit-pro-react";
import { mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.js";

const courseCardStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
  },
  root: {
    width: 350,
    minWidth: 310,
    maxWidth: 350,
    margin: "20px",
  },
  container: {
    minHeight: 300,
    boxShadow: "0 0 24px 0 rgba(0, 0, 0, 0.09)",
  },
  imageContainer: {
    height: 165,
    width: "100%",
  },
  longCard: {
    height: 300,
  },
  image: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    objectPosition: "top center",
  },
  infoContainer: {
    padding: "15px 10px",
    display: "flex",
    flexDirection: "column",
    height: "45%",
    paddingBottom: 10,
    backgroundColor: whiteColor,
  },
  courseTitle: {
    fontWeight: "bold",
    textTransform: "capitalize",
    fontSize: "0.9rem",
    margin: 0,
  },
  ratingContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: 26,
  },
  ratingStar: {
    fill: warningColor[0],
    fontSize: "1rem",
  },
  rating: {
    color: warningColor[0],
    fontSize: "0.8rem",
    marginTop: 2,
    marginRight: 10,
  },
  ratingCount: {
    color: textColor[1],
    fontSize: "0.8rem",
    marginLeft: 10,
  },
  text: {
    color: textColor[0],
    fontSize: "0.8rem",
  },
  cardFooter: {
    justifySelf: "flex-end",
    marginTop: "auto",
    display: "flex",
    alignItems: "center",
  },
  timerIcon: {
    height: 16,
    width: 16,
    marginRight: 10,
  },
  bookButton: {
    padding: "5px 20px",
    justifySelf: "flex-end",
    marginLeft: "auto",
    "& > span:first-child": {
      color: whiteColor,
      fontSize: 14,
      fontWeight: "bold",
      textTransform: "capitalize",
    },
  },
});

export default courseCardStyle;
