import { makeStyles } from "@material-ui/core/styles";
import profileImage from "assets/images/myprofile.png";
import CourseCard from "components/CourseCard/CourseCard.js";
import CourseCardSkeleton from "components/CourseCard/CourseCardSkeleton.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import { Link } from "react-router-dom";
import api from "services/api.js";
import style from "./myCoursesStyle.js";

const useStyles = makeStyles(style);

export default function MyCourses(props) {
  const [loading, setLoading] = React.useState(true);
  const [myCourses, setMyCourses] = React.useState([]);
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    (async () => {
      try {
        setLoading(true);
        const response = (await api.getMyCourses()).data;
        setMyCourses(response);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    })();
  }, []);

  let myCoursesElements = [];

  if (myCourses.length > 0) {
    myCourses.forEach((course, index) => {
      if (index < 3)
        myCoursesElements.push(<CourseCard key={index} course={course} />);
    });
  }

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container + " " + classes.headerImageContainer}>
        <img className={classes.contactHeader} src={profileImage} />
      </div>
      <div className={classes.contactContainer}>
        <div className={classes.container + " " + classes.shadowContainer}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} className={classes.bg}>
              <div className={classes.section}>
                <h3 className={classes.heading}>My Courses</h3>
                {loading ? (
                  <GridContainer className={classes.coursesContainer}>
                    <CourseCardSkeleton />
                    <CourseCardSkeleton />
                    <CourseCardSkeleton />
                  </GridContainer>
                ) : (
                  <GridContainer className={classes.coursesContainer}>
                    {myCoursesElements.length > 0 ? (
                      myCoursesElements
                    ) : (
                      <span className={classes.noCoursesMessage}>
                        You are not in enrolled in any courses.{" "}
                        <Link
                          className={classes.gotoCoursesLink}
                          to={"courses"}
                        >
                          Go to courses
                        </Link>{" "}
                        to enroll in your first course.
                      </span>
                    )}
                  </GridContainer>
                )}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
