import { primaryColor } from "assets/jss/material-kit-pro-react";
import { mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.js";

const testimonialCardStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  testimonialCard: {
    maxWidth: "650px",
    margin: "60px auto",
    [theme.breakpoints.down("sm")]: {
      margin: "0px auto",
      maxWidth: "50vw",
      marginTop: 40,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "70vw",
    },
  },
  cardDescription: {
    marginTop: 20,
    whiteSpace: "pre-line",
  },
  cardTitle: {
    color: primaryColor[0],
  },
  job: {
    color: primaryColor[0],
  },
});

export default testimonialCardStyle;
