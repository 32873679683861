import contactBackground from "assets/images/contactBackground.png";
import { primaryColor, textColor } from "assets/jss/material-kit-pro-react";
import {
  container,
  mlAuto,
  mrAuto,
  whiteColor,
} from "assets/jss/material-kit-pro-react.js";

const contactUsStyle = (theme) => ({
  mrAuto,
  mlAuto,
  mAuto: {
    ...mrAuto,
    ...mlAuto,
  },
  container: {
    ...container,
    zIndex: 1,
  },
  contactRoot: {
    marginLeft: -15,
    marginRight: -15,
    padding: "60px 50px",
    paddingBottom: 80,
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none",
      padding: "40px 15px",
    },
  },
  contactHeader: {
    textAlign: "center",
    color: textColor[0],
    textTransform: "uppercase",
    marginBottom: 80,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginBottom: 40,
      fontSize: "1.5rem",
    },
  },
  contactUnderlinedHeaderText: {
    color: textColor[0],
    fontWeight: 300,
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
      marginBottom: 20,
      fontSize: "1.5rem",
    },
  },
  displayNoneMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  contactUnderlinedHeaderContainer: {
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "center",
    },
  },
  contactHeaderUnderline: {
    borderRadius: 100,
    backgroundColor: primaryColor[0],
    height: 5,
    width: 30,
    marginTop: 5,
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
      height: 1,
      marginBottom: 20,
    },
  },
  worldMap: {
    maxWidth: "110%",
    marginLeft: -60,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80vw",
      marginLeft: 0,
    },
  },
  flagsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  flags: {
    width: 400,
    height: 232,
    marginTop: 80,
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      height: "41%",
    },
  },
  responseMessage: {
    color: primaryColor[0],
    fontSize: 14,
    fontWeight: 100,
    paddingLeft: 5,
    opacity: 0,
    transition: "0.3s all",
  },
  responseMessageVisible: {
    opacity: 1,
  },
  reviewImageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  thankYouText: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  reviewImage: {
    maxWidth: 400,
    maxHeight: 302,
    margin: "80px 0px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 40,
    },
  },
  contactForm: {
    order: 2,
    backgroundImage: `url(${contactBackground})`,
    backgroundSize: "140%",
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    marginRight: -50,
    paddingRight: 65,
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none",
      order: 1,
      marginRight: 0,
      paddingRight: 15,
    },
  },
  locations: {
    order: 1,
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
  infoAreaContainer: {
    marginTop: 60,
    marginBottom: 60,
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
    },
  },
  button: {
    display: "flex",
    justifyContent: "center",
    padding: "7px 30px",
    borderRadius: 5,
    "& > span:first-child": {
      color: whiteColor,
      fontWeight: "bold",
    },
  },
});

export default contactUsStyle;
