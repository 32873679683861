import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React from "react";
import style from "./clientLogoStyle.js";

const useStyles = makeStyles(style);

export default function ClientLogo(props) {
  const { client } = props;
  const classes = useStyles();
  return (
    <div>
      <Card testimonial plain className={classes.clientCard}>
        <CardBody style={{ display: "flex" }} plain>
          <div className={classes.clientLogoContainer}>
            <img
              className={classes.clientLogo}
              src={client?.imgUrl}
              alt={client?.name}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
