import {
  createMuiTheme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button.js";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import api from "services/api.js";
import style from "./passwordFormStyle.js";

const useStyles = makeStyles(style);

const CssTextField = withStyles(style(createMuiTheme()).cssTextField)(
  TextField
);

export default function PasswordForm(props) {
  const { user } = props;
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [responseMessage, setResponseMessage] = React.useState({
    message: ".",
    visible: false,
    error: false,
  });

  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setValue,
    setError,
    getValues,
  } = useForm();

  const validationRules = {
    currentPassword: {
      required: "This field is required.",
    },
    newPassword: {
      required: "This field is required.",
      minLength: {
        value: 8,
        message: "Password should be at least 8 characters.",
      },
      maxLength: {
        value: 60,
        message: "Password cannot exceed 60 characters.",
      },
      validate: {
        notSameAsCurrentPassword: (value) =>
          value !== getValues("currentPassword") ||
          "New password must not be used before",
      },
    },
    confirmPassword: {
      required: "This field is required.",
      minLength: {
        value: 8,
        message: "Password should be at least 8 characters.",
      },
      maxLength: {
        value: 60,
        message: "Password cannot exceed 60 characters.",
      },
      validate: {
        notSameAsNewPassword: (value) =>
          value === getValues("newPassword") ||
          "Must be the same as the new password",
      },
    },
  };
  const handleUpdatePassword = async () => {
    try {
      setLoading(true);
      let info = {
        currentPassword: getValues("currentPassword"),
        newPassword: getValues("newPassword"),
      };
      await api.updateStudentPassword(info);
      setLoading(false);
      setTimeout(() => {
        setResponseMessage({
          visible: true,
          error: false,
          message: "Information updated successfully",
        });
        setValue("currentPassword", null);
        setValue("newPassword", null);
        setValue("confirmPassword", null);
      }, 1000);
      setTimeout(() => {
        setResponseMessage({
          ...responseMessage,
          visible: false,
          error: false,
        });
      }, 5000);
    } catch (err) {
      setLoading(false);
      if (err.response?.data?.errors?.length) {
        err.response.data.errors.forEach((error) => {
          console.log(error);
          if (error.location && error.location === "body") {
            setError(error.param, {
              type: "manual",
              message: error.msg,
            });
          }
        });
      } else {
        setLoading(false);
        setTimeout(() => {
          setResponseMessage({
            visible: true,
            error: true,
            message: "Something went wrong",
          });
        }, 1000);
        setTimeout(() => {
          setResponseMessage({
            ...responseMessage,
            visible: false,
            error: false,
          });
        }, 5000);
      }
    }
  };

  const classes = useStyles();
  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(handleUpdatePassword)}
    >
      <CssTextField
        id="currentPassword"
        key="currentPassword"
        name="currentPassword"
        type="password"
        error={errors.currentPassword?.message !== undefined}
        helperText={errors.currentPassword?.message}
        inputRef={register(validationRules.currentPassword)}
        label="Current Password"
        variant="outlined"
        size="small"
        className={classes.textFieldMarginBottom}
        InputProps={{ className: classes.textField }}
        onChange={(event) => {
          setValue("currentPassword", event.target.value);
        }}
      />
      <CssTextField
        id="newPassword"
        key="newPassword"
        name="newPassword"
        type="password"
        error={errors.newPassword?.message !== undefined}
        helperText={errors.newPassword?.message}
        inputRef={register(validationRules.newPassword)}
        label="New Password"
        variant="outlined"
        size="small"
        className={classes.textFieldMarginBottom}
        InputProps={{ className: classes.textField }}
        onChange={(event) => {
          setValue("newPassword", event.target.value);
        }}
      />
      <CssTextField
        id="confirmPassword"
        key="confirmPassword"
        name="confirmPassword"
        type="password"
        error={errors.confirmPassword?.message !== undefined}
        helperText={errors.confirmPassword?.message}
        inputRef={register(validationRules.confirmPassword)}
        label="Confirm Password"
        variant="outlined"
        size="small"
        className={classes.textFieldMarginBottom}
        InputProps={{ className: classes.textField }}
        onChange={(event) => {
          setValue("confirmPassword", event.target.value);
        }}
      />
      <div
        className={
          classes.responseMessage +
          " " +
          classes.marginBottomDesktop +
          " " +
          (responseMessage.visible
            ? classes.responseMessageVisible + " "
            : "") +
          (responseMessage.error ? classes.dangerText : "")
        }
      >
        {responseMessage.message}
      </div>
      <div className={classes.saveButton}>
        <Button
          type="submit"
          color="primary"
          className={classes.button}
          round
          loading={loading}
        >
          Save
        </Button>
      </div>
    </form>
  );
}
