import { makeStyles } from "@material-ui/core/styles";
import contactUsImage from "assets/images/contact.png";
import classNames from "classnames";
import ContactUs from "components/ContactUs/ContactUs.js";
import React from "react";
import style from "./contactUsPageStyle.js";

const useStyles = makeStyles(style);

export default function ContactUsPage() {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const classes = useStyles();
  return (
    <div>
      <div className={classNames(classes.root)}>
        <div className={classes.container + " " + classes.headerImageContainer}>
          <img className={classes.contactHeader} src={contactUsImage} />
        </div>
        <div className={classes.contactContainer}>
          <div className={classes.container + " " + classes.shadowContainer}>
            <ContactUs />
          </div>
        </div>
      </div>
    </div>
  );
}
