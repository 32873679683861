import { grayColor, whiteColor } from "assets/jss/material-kit-pro-react";

const customModalStyle = (theme) => ({
  modalRoot: {
    overflow: "auto",
    display: "block",
  },
  container: {
    height: "100%",
  },
  modal: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "fit-content",
      minWidth: "500px",
      margin: "auto",
    },
    overflow: "visible",
    maxHeight: "unset",
    width: "100%",
    margin: "auto !important",
  },
  modalCloseButton: {
    "&, &:hover": {
      color: grayColor[0],
    },
    "&:hover": {
      opacity: "1",
    },
    cursor: "pointer",
    padding: "1rem",
    margin: "-1rem 0 -1rem auto",
    backgroundColor: "transparent",
    border: "0",
    WebkitAppearance: "none",
    float: "right",
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "1",
    textShadow: "0 1px 0 " + whiteColor,
    opacity: ".5",
    position: "absolute",
    top: 20,
    right: 4,
    zIndex: 1,
    padding: 0,
  },
  modalClose: {
    width: "25px !important",
    height: "25px !important",
    fill: "white",
    [theme.breakpoints.up("sm")]: {
      fill: "black",
      width: "16px !important",
      height: "16px !important",
    },
  },
  modalBody: {
    padding: 0,
    position: "relative",
    overflow: "visible",
  },
  gridContainer: { margin: 0 },
  gridItem: {
    padding: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 400,
    },
  },
});

export default customModalStyle;
