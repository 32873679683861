import { makeStyles } from "@material-ui/core/styles";
import coursesImage from "assets/images/courses.png";
import paymentSuccessImg from "assets/images/material-done.png";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import style from "./paymentSuccessStyle.js";

const useStyles = makeStyles(style);

export default function PaymentSuccess(props) {

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

  }, []);


  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img
          className={classes.coursesHeaderImage}
          src={coursesImage}
          alt="coursesImage"
        />
      </div>
      <div className={classes.container + " " + classes.shadowContainer}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <div>
              <h3 className={classes.paymentSuccessText}>YOUR PAYMENT IS SUCCESSFULLY COMPLETED </h3>

              <img
                className={classes.paymentSuccessImg}
                src={paymentSuccessImg}
                alt="paymentSuccessImg"
              />
            </div>

          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
