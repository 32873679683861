import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import styles from "./customModalStyle";

const useStyles = makeStyles(styles);

export default function CustomModal(props) {
  const classes = useStyles();
  const { open, onClose, children, style } = props;
  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
        container: classes.container,
      }}
      open={open}
      keepMounted
      onClose={onClose}
    >
      <Button
        simple
        className={classes.modalCloseButton}
        key="close"
        aria-label="Close"
        onClick={onClose}
      >
        <Close className={classes.modalClose} />
      </Button>
      <DialogContent className={classes.modalBody}>
        <GridContainer justify="center" className={classes.gridContainer}>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            className={classes.gridItem}
            style={style}
          >
            {children}
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
}
